import { Dialog } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { Button, formateEpochToShortMonthDate } from "ww-framework";
import { CalendarDataContext, orgUtils } from "ww-stores";

const width = window.innerWidth;

const EarlyClockInView = ({ rebuildCalendar, editShiftAction }) => {
	const { setNotificationModalOpen, earlyClockInData, activeDay, setEditShift } = useContext(CalendarDataContext);
	const { shiftsClockedInEarly, punchesClockedInEarly } = earlyClockInData;
	const [shifts, setShifts] = useState(shiftsClockedInEarly);
	const [loading, setLoading] = useState(false);

	const closeModal = () => {
		setNotificationModalOpen(false);
	};

	const timeBeforeShiftStart = (punch, shift) => {
		if (!punch || !shift) return;
		const shiftStartTimestamp = shift.shiftStart;
		const punchInTimestamp = punch.in;
		const differenceInSeconds = shiftStartTimestamp - punchInTimestamp;
		const hours = differenceInSeconds / 3600;

		if (hours >= 1) {
			const wholeHours = Math.floor(hours);
			const minutes = Math.floor((hours - wholeHours) * 60);
			const hoursLabel = wholeHours === 1 ? "hour" : "hours";
			const minutesLabel = minutes === 1 ? "minute" : "minutes";

			if (minutes > 0) {
				return `${wholeHours} ${hoursLabel} and ${minutes} ${minutesLabel}`;
			} else {
				return `${wholeHours} ${hoursLabel}`;
			}
		} else {
			const minutes = Math.floor(hours * 60);
			const minutesLabel = minutes === 1 ? "minute" : "minutes";
			return `${minutes} ${minutesLabel}`;
		}
	};

	const punchInShiftAtShiftStart = async (shift) => {
		setLoading(true);
		const punch = punchesClockedInEarly?.find((p) => p.shiftID === shift.id);
		try {
			const data = { ...punch };
			data.in = shift?.shiftStart;
			await orgUtils.savePunchOfMember(data);
			setShifts((prev) => prev.filter((s) => s.id !== shift.id));
			rebuildCalendar(activeDay.date);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const editAllShiftsClockIn = async () => {
		for (let i = 0; i < shiftsClockedInEarly?.length; i++) {
			await punchInShiftAtShiftStart(shiftsClockedInEarly[i], false);
		}
		closeModal();
	};

	const callEditShift = (e, shift) => {
		closeModal();
		setEditShift(shift);
		editShiftAction(e, "shift");
	};

	const approveEarlyClockIn = async (shift) => {
		setLoading(true);
		const punch = punchesClockedInEarly?.find((p) => p.shiftID === shift.id);
		//set status to APPROVED
		try {
			const data = { ...punch };
			data.status = "APPROVED";
			await orgUtils.savePunchOfMember(data);
			setShifts((prev) => prev.filter((s) => s.id !== shift.id));
			rebuildCalendar(activeDay.date);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	return (
		<div className="p-3">
			<div className="sm:flex sm:items-start">
				<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-white border border-rose-200 sm:mx-0 sm:h-10 sm:w-10">
					<ExclamationCircleIcon className="h-6 w-6 text-rose-600" aria-hidden="true" />
				</div>
				<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
					<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
						The following shifts were clocked in early!
					</Dialog.Title>
					<div className="mt-2">
						<div className="text-sm text-gray-500 flex flex-col">
							{`The following shifts were clocked in earlier than rostered start time:`}
							<p className="mt-3" />
							{shifts.map((shift) => {
								const punch = punchesClockedInEarly?.find((p) => p.shiftID === shift.id);
								return (
									<div key={shift?.id} className="flex items-end gap-2 mb-1">
										<div className="my-0 border border-pink-600 rounded flex items-center flex-grow">
											<span className="text-black px-3 flex-1">{shift?.member?.person?.username}</span>
											<div className="text-xs text-white bg-pink-600 py-1.5 px-2 flex-3">
												<span className="text-sm font-bold block">{timeBeforeShiftStart(punch, shift)}</span>
												<span className="block">{`before the rostered start time on ${formateEpochToShortMonthDate(
													shift.baseDay
												)}`}</span>
											</div>
										</div>

										{width > 500 && (
											<>
												<Button
													label="Use shift start time"
													onClick={() => punchInShiftAtShiftStart(shift)}
													loading={loading}
													className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-600 text-xs py-1.5 px-2 rounded w-full flex items-center justify-center"
												/>
												<Button
													label="Approve"
													onClick={(e) => approveEarlyClockIn(shift)}
													loading={loading}
													className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-600 text-xs py-1.5 px-2 rounded w-full flex items-center justify-center"
												/>
											</>
										)}
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			<div className="mt-10 flex justify-end gap-5">
				{shifts?.length > 0 && (
					<Button
						label="Use shift start for all"
						onClick={() => editAllShiftsClockIn()}
						loading={loading}
						colors="bg-red-500 hover:bg-red-600 focus:ring-red-600"
					/>
				)}
				<Button label="Okay" onClick={() => closeModal()} loading={loading} />
			</div>
		</div>
	);
};

export default EarlyClockInView;

import React, { useContext, useEffect, useState } from "react";
import { CheckingAuth, Navigate, useLocation, useNavigate } from "ww-framework";
import { OrganisationContext, PersonContext, SubscriptionContext, orgUtils, personUtils } from "ww-stores";
import Header from "../../Views/General/Header.jsx";
import { ContactAdmin } from "../../Views/Organisation/ContactAdmin";
import Subscribe from "../../Views/Organisation/Subscribe";
import { notifyLateCheckIns } from "../../ww-stores/Organisation/checkIns.js";

export const AuthRoute = ({ component: RouteComponent, forAdmin = false }) => {
	const [loggedInStatus, setLoggedInStatus] = useState("CHECKING"); // CHECKING, ALREADY_LOGGED_IN, SIGN_IN_REQUIRED
	const [subscriptionStatus, setSubscriptionStatus] = useState("SUBSCRIBED"); // SUBSCRIBED, NOT_SUBSCRIBED, EXPIRED
	const { person, setPerson } = useContext(PersonContext);
	const { setOrganisation } = useContext(OrganisationContext);
	const { setSubscription } = useContext(SubscriptionContext);
	let navigate = useNavigate();
	let location = useLocation();
	// Run this once when some tries to go an authenticated route. This ONLY gets called once per app load in App.js, not on every navigation.
	useEffect(() => {
		const checkUser = async () => {
			try {
				const authenticatedUser = await personUtils.checkAuth();
				if (authenticatedUser.isAuthenticated) {
					let buildPerson = { ...person, ...authenticatedUser };

					const profile = await personUtils.getProfile(buildPerson);
					buildPerson = { ...buildPerson, ...profile };
					if (buildPerson?.isPayRoll) navigate("/clock-in-out");
					// if (!buildPerson.currentOrganisation) {
					const userInvitations = await personUtils.getInvitations(buildPerson.email);
					if (userInvitations.length > 0) {
						buildPerson = { ...buildPerson, ...{ invitations: userInvitations } };
						setTimeout(
							() =>
								navigate(`/invitations`, {
									state: {
										from: location,
										backgroundLocation: "/dashboard", // no need to change as landing page will be here
										file: "/Person/Invitations"
									}
								}),
							2000
						);
					}
					if (buildPerson.currentOrganisation) {
						const currentOwnerOrganisations =
							buildPerson?.organisations?.items?.filter((k) => k?.owner === buildPerson?.currentOrganisation?.owner)?.length ?? 0;

						const isAdmin =
							buildPerson.currentOrganisation && buildPerson.person === buildPerson.currentOrganisation.owner ? true : false;
						// Only load the more detailed org info is the user is an admin (well for now anyway).

						// Initial set the organisation. The only other time "setOrganisation" should be called is after it's updated.
						orgUtils.get(buildPerson.currentOrganisation).then(async (organisation) => {
							let subscription = {};
							const {
								id,
								name,
								owner,
								members,
								deletedMembers,
								allMembers,
								roles,
								address,
								phone,
								hrEmail,
								paidBreak,
								allPaidBreak,
								disableClockIn,
								enablePhoto,
								logoURI,
								report,
								StaffCost,
								vat,
								drinkPercentage,
								startDay,
								foodPercentage,
								staffInputhourlyRate,
								staffClockInView,
								staffHolidayPay,
								departments,
								checkIns,
								lateClockOutNotificationHours,
								enableStaffTimeOff,
								enableStaffPreferences,
								earlyClockInNotificationHours
							} = organisation;
							// from here
							if (currentOwnerOrganisations > 1) {
								const firstOrg = buildPerson?.organisations?.items.reduce((a, b) =>
									a?.organisation?.createdAt < b?.organisation?.createdAt ? a : b
								)?.organisation;

								const first = await orgUtils.get(firstOrg); //get subscription inly instead.
								subscription = first?.subscription;
							} else subscription = organisation?.subscription;
							// to here
							setSubscription(subscription?.items?.[0]);
							if (organisation.owner === buildPerson.sub && subscription?.items?.length === 0) {
								setSubscriptionStatus("NOT_SUBSCRIBED");
							}

							window.sessionStorage.setItem("mam", subscription?.items?.[0]?.quantity ?? 0);
							if (
								subscription?.items?.length > 0 &&
								subscription?.items?.[0]?.SubscriptionStatus !== "active" &&
								subscription?.items?.[0]?.SubscriptionStatus !== "trialing" &&
								subscription?.items?.[0]?.SubscriptionStatus !== "incomplete" &&
								subscription?.items?.[0]?.SubscriptionStatus !== undefined
							) {
								setSubscriptionStatus("EXPIRED");
							}

							let assignedAdmin = members?.items.find((l) => l.personID === buildPerson.id).assignedAdmin;
							let assignedRequests = members?.items.find((l) => l.personID === buildPerson.id).assignedRequests;
							let assignedSuperAdmin = members?.items.find((l) => l.personID === buildPerson.id).assignedSuperAdmin;
							let assignedFinancials = members?.items.find((l) => l.personID === buildPerson.id).assignedFinancials;

							let loadedOrg = {
								id,
								name,
								owner,
								address,
								phone,
								hrEmail,
								paidBreak,
								allPaidBreak,
								disableClockIn,
								enablePhoto,
								vat,
								StaffCost,
								drinkPercentage,
								startDay,
								foodPercentage,
								staffInputhourlyRate,
								staffClockInView,
								staffHolidayPay,
								roles: roles?.items || [],
								logoURI: logoURI,
								...(await orgUtils.mapOrgMembers(organisation)),
								logo: await orgUtils.loadLogo(organisation),
								report: await orgUtils.mapReport(report?.items || [], organisation.id),
								departments: departments?.items || [],
								maxAllowedMembers: subscription?.items?.[0]?.quantity || 0,
								subscription: subscription?.items?.[0],
								checkIns,
								lateClockOutNotificationHours,
								enableStaffTimeOff,
								enableStaffPreferences,
								earlyClockInNotificationHours
							};
							buildPerson = {
								...buildPerson,
								...{
									isAdmin: isAdmin,
									assignedAdmin: assignedAdmin,
									assignedRequests: assignedRequests,
									assignedSuperAdmin: assignedSuperAdmin,
									assignedFinancials: assignedFinancials,
									currentOrganisation: loadedOrg
								}
							}; // Keep updating local value so finished async functions have correct details
							setPerson(buildPerson);
							setOrganisation(loadedOrg);
							const currentOrganisationIndex = buildPerson.organisations.items.findIndex(
								(k) => k.organisation.id === buildPerson.currentOrganisation.id
							);
							window.sessionStorage.setItem("activeOrg", currentOrganisationIndex);
							const { logo } = loadedOrg;
							if (logoURI && !logo) {
								orgUtils.loadLogo(loadedOrg).then((logoDetails) => {
									loadedOrg = { ...loadedOrg, ...{ logo: logoDetails } };
									setOrganisation(loadedOrg);
								});
							}
							notifyLateCheckIns({ currentUser: buildPerson, org: loadedOrg, navigate, location });
						});
					}

					const { avatarURI, avatar } = buildPerson;
					if (avatarURI && !avatar) {
						personUtils.loadAvatar(buildPerson).then((avatarDetails) => {
							buildPerson = { ...buildPerson, ...{ avatar: avatarDetails } }; // Keep updating local value so finished async functions have correct details
							setPerson(buildPerson);
						});
					}

					setPerson(buildPerson);

					setTimeout(() => setLoggedInStatus("ALREADY_LOGGED_IN"), 1500);
				} else {
					setTimeout(() => setLoggedInStatus("SIGN_IN_REQUIRED"), 1500);
				}
			} catch (error) {
				console.log("Error checking authenticated user:", error);
			}
		};
		checkUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (person?.isPayRoll && (location.pathname !== "/clock-in-out" || location.pathname === "/dashboard"))
		return <Navigate to="/clock-in-out" state={{ from: location }} replace />;
	return loggedInStatus === "CHECKING" ? (
		<CheckingAuth />
	) : loggedInStatus === "ALREADY_LOGGED_IN" ? (
		person.isAdmin && subscriptionStatus !== "SUBSCRIBED" ? (
			<Header shadow={true}>
				<Subscribe />
			</Header>
		) : subscriptionStatus !== "SUBSCRIBED" && !person?.isPayRoll && person?.currentOrganisation !== "" ? (
			<Header shadow={true}>
				<ContactAdmin />
			</Header>
		) : person?.currentOrganisation !== "" && !(person?.isAdmin || person?.assignedAdmin) && forAdmin ? (
			<Navigate to="/dashboard" state={{ from: location }} replace />
		) : (
			<Header shadow={true}>
				<RouteComponent />
			</Header>
		)
	) : (
		<Navigate to="/employee-manager-signin" state={{ from: location }} replace />
	);
};

import { BellIcon } from "@heroicons/react/outline";
import React, { useContext } from "react";
import { CalendarDataContext } from "ww-stores";

const NotificationButton = () => {
	const { earlyClockInData, lateClockOutData, setNotificationModalOpen } = useContext(CalendarDataContext);

	if (!lateClockOutData && !earlyClockInData) return <></>;

	const shiftsNotClockedOut = lateClockOutData ? lateClockOutData?.shiftsNotClockedOut : [];
	const shiftsClockedInEarly = earlyClockInData ? earlyClockInData?.shiftsClockedInEarly : [];

	return (
		<div className="flex justify-center items-end">
			<button
				type="button"
				onClick={() => {
					setNotificationModalOpen(true);
				}}
			>
				<div className="px-1 py-1 mt-1 text-sm bg-pink-600 hover:bg-pink-700 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 rounded-md">
					<div className="flex flex-row items-center px-1">
						<div className="relative inline-block">
							<div className="pt-1 pr-1">
								<BellIcon className="h-5 w-5 text-white" aria-hidden="true" />
							</div>
							<div className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 bg-white text-darkww-700 text-xs rounded-full mr-0.5">
								{shiftsNotClockedOut?.length + shiftsClockedInEarly?.length}
							</div>
						</div>
					</div>
				</div>
			</button>
		</div>
	);
};

export default NotificationButton;

// const LateClockOutNotificationButton = () => {
// 	const { lateClockOutData, setNotificationModalOpen } = useContext(CalendarDataContext);
// 	const shiftsNotClockedOut = lateClockOutData ? lateClockOutData?.shiftsNotClockedOut : [];
// 	return (
// 		<div className="flex justify-center items-end">
// 			<button
// 				type="button"
// 				onClick={() => {
// 					setNotificationModalOpen(true);
// 				}}
// 			>
// 				<div className="px-1 py-1 mt-1 text-sm bg-pink-600 hover:bg-pink-700 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 rounded-md">
// 					<div className="flex flex-row items-center px-1">
// 						<div className="relative inline-block">
// 							<div className="pt-1 pr-1">
// 								<BellIcon className="h-5 w-5 text-white" aria-hidden="true" />
// 							</div>
// 							<div className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 bg-white text-darkww-700 text-xs rounded-full mr-0.5">
// 								{shiftsNotClockedOut?.length}
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</button>
// 		</div>
// 	);
// };

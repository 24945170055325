import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { Fragment, useContext, useState } from "react";
import { Button, Menu, Transition as Transitions } from "ww-framework";
import { CalendarDataContext, OrganisationContext, PersonContext, personUtils } from "ww-stores";
import { MemberAvatar } from "./Calendar";

const EmployeeViewStaffList = ({ index, members }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const [member] = useState(organisation.members?.find((m) => m.person === person.person));
	const {
		calendarDays,
		mode,
		confirmHours,
		templatesVisible,
		setTemplateVisible,
		filterByRole,
		zoom,
		punchData,
		isHoursConfirm,
		setIsHoursConfirm,
		setFilterByDepartment,
		setFilterByRole
	} = useContext(CalendarDataContext);

	const modeStyle = mode.id === "W" ? "row-span-7" : "row-span-1";
	const areAllChecked = members.every((_, index) => isHoursConfirm.values[index]);

	const handleSelectAllChange = (e) => {
		let hoursConfirmBuffer = { values: {} };
		members.forEach((member, index) => {
			hoursConfirmBuffer.values[index] = e.target.checked;
		});
		setIsHoursConfirm(hoursConfirmBuffer);
	};

	const filterShifts = async (e, typeId, type = "dept") => {
		if (type === "dept") setFilterByDepartment(typeId);
		if (type === "role") setFilterByRole(typeId);
	};

	const confirmingHours = (e, m, i) => {
		setIsHoursConfirm({ values: { ...(isHoursConfirm.values || ""), [i]: e.target.checked } });
	};

const calculateMemberWeekTime = (member, calendarDays, organisation) => {
	const uniqueCalendarDays = calendarDays.filter(
		(value, index, self) => index === self.findIndex((t) => new Date(t.date).toDateString() === new Date(value.date).toDateString())
	);

	return uniqueCalendarDays
		.map((cd) => {
			if (cd && cd.shifts) {
				const filteredShifts = cd.shifts.filter((k) =>
					filterByRole !== "ALL" ? k.roleID === filterByRole && k.memberID === member.orgUserId : k.memberID === member.orgUserId
				);

				return filteredShifts.reduce((previousTotal, currentShift) => {
					let breakDuration = 0;

					// Check conditions similar to punch logic for deducting breaks
					if (currentShift?.hasBreak && (currentShift?.unPaidBreak || organisation?.allPaidBreak === true)) {
						if (currentShift?.breakDuration) {
							// Fixed-duration break
							breakDuration = currentShift?.breakDuration * 60; // Convert minutes to seconds
						} else if (currentShift?.breakStart && currentShift?.breakEnd) {
							breakDuration = currentShift?.breakEnd - currentShift?.breakStart; // Directly in seconds
						}
					}

					const hoursWorked = (currentShift?.shiftEnd - currentShift?.shiftStart - breakDuration) / 3600;
					return previousTotal + hoursWorked;
				}, 0);
			}
			return 0;
		})
		.reduce((partialSum, a) => partialSum + a, 0);
};
const calculatePunchTime = (member, calendarDays, organisation) => {
	// Filter to ensure unique days, considering possible duplicates due to daylight savings.
	const uniqueCalendarDays = calendarDays.filter(
		(value, index, self) => index === self.findIndex((t) => new Date(t.date).toDateString() === new Date(value.date).toDateString())
	);

	const hours = uniqueCalendarDays
		.map((cd) => {
			if (cd && cd?.shifts && cd?.shifts.length) {
				return cd?.shifts
					.filter((k) => k.memberID === member.orgUserId)
					.reduce((previousTotal, currentShift) => {
						let breakDuration = 0;
						let punch = punchData.find((p) => p.shiftID === currentShift.id);

						// Check if there are punch breaks
						if (punch && punch?.breaks?.length > 0) {
							breakDuration = punch.breaks.reduce((previousTotal, currentBreak) => {
								let b = JSON.parse(currentBreak);
								let breakTime = (b.end ?? 0) - b.start;

								// If break is zero-duration (same start and end), mark as valid but no deduction
								if (b.start === b.end) {
									breakTime = 0;
								}

								return previousTotal + breakTime;
							}, 0);
						} else {
							// If no punch breaks, check if shift's break should be deducted
							if (currentShift.hasBreak && (currentShift.unPaidBreak || organisation.allPaidBreak)) {
								// Use predefined break duration
								breakDuration = currentShift.breakDuration * 60; // Convert minutes to seconds

								// Add timestamp-based breaks if provided
								if (currentShift.breakStart && currentShift.breakEnd) {
									const timestampBreakDuration = currentShift.breakEnd - currentShift.breakStart;
									breakDuration += timestampBreakDuration;
								}
							}
						}

						// Calculate work time excluding breaks
						let calculatedHours;
						if (punch?.out > punch?.in) {
							calculatedHours = (punch?.out - punch?.in - breakDuration) / 3600;
						} else if (punch?.out < punch?.in) {
							// Handle shifts that span over midnight
							calculatedHours = (punch?.out + 86400 - punch?.in - breakDuration) / 3600;
						} else {
							calculatedHours = 0;
						}

						return previousTotal + calculatedHours;
					}, 0);
			}
			return 0;
		})
		.reduce((partialSum, a) => partialSum + a, 0);

	return Math.round(hours * 100) / 100; // Return the rounded result to 2 decimal places
};

return (
		<div
			key={`Employee-List-${index}`}
			className={`${modeStyle} sticky left-0 bg-gray-80 border-gray-500 col-span-1 cursor-pointer bg-white hover:border-darkww-500 border-4 rounded-lg shadow-lg sm:min-w-[190.4px] min-w-[130.4px] w-full mr-2 z-30`}
		>
			{(person.isAdmin || person.assignedAdmin) && !zoom && <div className="h-1" />}
			{(person.isAdmin || person.assignedAdmin) && (
				<div className="flex justify-center">
					<Button
						onClick={() => {
							setTemplateVisible(!templatesVisible);
						}}
						label="Templates"
						className="rounded-md shadow-sm text-sm font-medium text-white bg-purple-700 hover:bg-purple-600 shadow-sm my-1 py-1 px-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
					/>
					{(person?.isAdmin || person?.assignedAdmin) &&
						zoom &&
						organisation?.roles &&
						organisation?.roles.constructor === Array &&
						organisation?.roles?.length > 0 && (
							<Menu as="div" className="">
								{({ open }) => (
									<>
										<Menu.Button className="text-md border-2 mt-2 ml-2 border-purple-700 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500">
											<ChevronDownIcon className="h-4 w-4 text-purple-700 sm:block" aria-hidden="true" />
										</Menu.Button>
										<Transitions
											show={open}
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items
												static
												className="absolute mt-2 w-max rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
											>
												<div className="sm:block">
													<Menu.Item>
														<div
															className={`block px-4 py-2 text-sm cursor-pointer ${
																filterByRole === "ALL" ? "bg-lightww-600 text-white" : "text-gray-700"
															}`}
															onClick={(e) => filterShifts(e, "ALL", "role")}
														>
															All
														</div>
													</Menu.Item>
													{organisation.roles?.map((d) => (
														<Menu.Item key={d.id}>
															<div
																className={`block px-4 py-2 text-sm cursor-pointer ${
																	filterByRole === d.id ? "bg-lightww-600 text-white" : "text-gray-700 "
																}`}
																onClick={(e) => filterShifts(e, d.id, "role")}
															>
																{d.name}
															</div>
														</Menu.Item>
													))}
												</div>
											</Menu.Items>
										</Transitions>
									</>
								)}
							</Menu>
						)}
				</div>
			)}
			{!zoom && (
				<div className="text-xl font-semibold text-purple-700 mt-1 text-center flex justify-center gap-1">
					{person.isAdmin || person.assignedAdmin ? "Roles" : "Employees"}
					{(person.isAdmin || person.assignedAdmin) &&
						organisation?.roles &&
						organisation?.roles.constructor === Array &&
						organisation?.roles?.length > 0 && (
							<Menu as="div" className="">
								{({ open }) => (
									<>
										<Menu.Button className="text-md border-2 mt-1 ml-1 border-purple-700 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500">
											<ChevronDownIcon className="h-4 w-4 text-purple-700 sm:block" aria-hidden="true" />
										</Menu.Button>
										<Transitions
											show={open}
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items
												static
												className="absolute mt-2 w-max rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
											>
												<div className="sm:block">
													<Menu.Item>
														<div
															className={`block px-4 py-2 text-sm cursor-pointer ${
																filterByRole === "ALL" ? "bg-lightww-600 text-white" : "text-gray-700"
															}`}
															onClick={(e) => filterShifts(e, "ALL", "role")}
														>
															All
														</div>
													</Menu.Item>
													{organisation.roles?.map((d) => (
														<Menu.Item key={d.id}>
															<div
																className={`block px-4 py-2 text-sm cursor-pointer ${
																	filterByRole === d.id ? "bg-lightww-600 text-white" : "text-gray-700 "
																}`}
																onClick={(e) => filterShifts(e, d.id, "role")}
															>
																{d.name}
															</div>
														</Menu.Item>
													))}
												</div>
											</Menu.Items>
										</Transitions>
									</>
								)}
							</Menu>
						)}
				</div>
			)}
			{confirmHours && !areAllChecked && (
				<div className="mt-1 -mb-2 font-semibold text-purple-700 font-semibold flex justify-start items-center">
					<input
						id="selectAll"
						aria-describedby="select-all-description"
						name="selectAll"
						type="checkbox"
						className="focus:ring-darkww-500 h-4 w-4 text-darkww-600 border-gray-300 rounded"
						checked={areAllChecked}
						onChange={handleSelectAllChange}
					/>
					<label htmlFor="selectAll" className="ml-2">
						All
					</label>
				</div>
			)}
			{members?.length > 0 && (
				<div>
					{(person?.isAdmin || person?.assignedAdmin) && !zoom && <div className={!(confirmHours && !areAllChecked) ? "mt-6" : "-mt-1"} />}
					{mode.id === "W" && (
						<div>
							{(person?.isAdmin || person?.assignedAdmin) && !zoom && (
								<div className={!(confirmHours && !areAllChecked) ? "mt-5" : "mt-0"} />
							)}
							{members.map((memberDetails, k) => {
								const maxHours = memberDetails?.maxHours ?? 48; 
								const totalWorkedHours = calculateMemberWeekTime(memberDetails, calendarDays);
								const hasExceededMaxHours = totalWorkedHours > maxHours;
								let punchedHours = calculatePunchTime(memberDetails, calendarDays, person.currentOrganisation).toFixed(1) ?? 0;
								return (
									<div className={`${zoom ? "" : "mt-1"} relative`} key={`${k}-member`}>
										<div className={`${zoom ? "h-9" : "h-18"} flex items-center`}>
										<div className={`flex flex-row flex-1 justify-end py-0 shadow-md rounded-md max-w-full`}>
											{/* <div className={`flex flex-row flex-1 justify-end py-0 shadow-md rounded-md max-w-full ${hasExceededMaxHours ? "bg-red-200" : "bg-white"}`}> */}
												{confirmHours && (
													<div className="relative self-center">
														<input
															id="assignedAdmin"
															aria-describedby="invite-description"
															name="assignedAdmin"
															type="checkbox"
															className="focus:ring-darkww-500 h-4 w-4 text-darkww-600 border-gray-300 rounded"
															defaultChecked={isHoursConfirm.values[k]}
															onChange={(e) => confirmingHours(e, member, k)}
														/>
													</div>
												)}
												<div className={`ml-0.5 flex ${zoom ? "h-8" : "h-16"} flex-1 items-center`}>
													<MemberAvatar member={memberDetails} />
													<div className="pl-1 text-left">
														{/* {hasExceededMaxHours && (
															<div className="text-pink-600 text-xs font-bold absolute -top-1.5 right-1 ml-2 mt-1">Max {`${maxHours}`} hrs</div>
														)} */}
														{confirmHours ? (
															<div className="text-sm font-medium truncate max-w-[11vh]">{personUtils.displayName(memberDetails)}</div>
														) : (
															<div className="text-sm font-medium truncate max-w-[15vh]">{personUtils.displayName(memberDetails)}</div>
														)}
													</div>
												</div>{" "}
												{(person?.isAdmin || person?.assignedAdmin) && (
													<div
														className={`flex flex-col relative justify-center flex ${zoom ? "text-[10px]" : "text-sm"} text-darkww-900 items-center`}>
														<span className="flex">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 24 24"
																fill="#db2777"
																className={`flex ${zoom ? "w-3 h-3" : "w-4 h-4"}`}>
																<path
																	fillRule="evenodd"
																	d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
																	clipRule="evenodd"
																/>
															</svg>
															<span className="ml-0.5 mr-0.5 font-semibold">
																{calculateMemberWeekTime(memberDetails, calendarDays, organisation)}
															</span>
														</span>
														{confirmHours && (
															<span className={`flex ${zoom ? "mt-0" : "mt-1"}`}>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	fill="#db2777"
																	className={`flex ${zoom ? "w-3 h-3" : "w-4 h-4"}`}>
																	<path
																		fillRule="evenodd"
																		d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
																		clipRule="evenodd"
																	/>
																</svg>
																<span className="ml-0.5 mr-0.5 font-semibold">
																	{confirmHours ? (punchedHours < 0 ? " /NA" : "" + punchedHours) : ""}
																</span>
															</span>
														)}
													</div>
												)}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default EmployeeViewStaffList;

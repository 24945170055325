import { Dialog } from "@headlessui/react";
import { ArrowCircleLeftIcon, ArrowCircleRightIcon, CalendarIcon, ClockIcon, ZoomInIcon, ZoomOutIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { Fragment, useContext, useState } from "react";
import { Menu, Transition as Transitions, notifyUser, showFailure, showSuccess, useSimpleMessage } from "ww-framework";
import ShiftCopyButton from "../ShiftCopyButton";
// import ExportCsv from "./ExportCsv";
// import { MemberListItem } from "./ShiftForm";
import { useReactToPrint } from "react-to-print";
import { OrganisationContext, PersonContext, orgUtils } from "ww-stores";
import { FoodIcon, MartiniIcon } from "../../../ww-images/MartiniIcon";
import { TargetIcon } from "../../../ww-images/TargetIcon";
import { CalendarDataContext, modes } from "../../../ww-stores/Calendar/calender";
import NotificationButton from "./Notification/NotificationButton";

const HeaderControlPanel = ({ buildCalendar, componentRef }) => {
	const { person } = useContext(PersonContext);
	const { organisation } = useContext(OrganisationContext);
	const { setMessage } = useSimpleMessage();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [notifyAll, setNotifyAll] = useState(false);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	});
	const {
		mode,
		setMode,
		selectedDay,
		setSelectedDay,
		calendarDays,
		setIsLoading,
		width,
		setOverLayOpen,
		shift,
		totalRate,
		clockedTotalRate,
		weeklyLiveCost,
		rateOrHour,
		lateClockOutData,
		setRateOrHour,
		setOverNoteParticularDayLayOpen,
		setOverLayRosterTemplateOpen,
		view,
		setView,
		setConfirmHours,
		averageTaking,
		setTimeOffModuleOpen,
		filterByDepartment,
		setFilterByDepartment,
		setFilterByRole,
		holidayPay,
		setHolidayPay,
		paidTimeOff,
		setPaidTimeOff,
		sortByRole,
		setSortByRole,
		zoom,
		setZoom
	} = useContext(CalendarDataContext);
	const firstDayOfWeek = calendarDays[0];
	const changeCalendar = (direction) => {
		let newDate;
		if (mode.id === "W") {
			const daysToMove = direction === "DOWN" ? -7 : 7;
			newDate = new Date(firstDayOfWeek?.date);
			newDate.setDate(firstDayOfWeek?.date?.getDate() + daysToMove);
		}
		if (mode.id === "M") {
			const monthsToMove = direction === "DOWN" ? -1 : 1;
			let year = selectedDay.getFullYear();
			let currentMonth = selectedDay.getMonth(); // getMonth() is 0-indexed
			const nextOrPreviousMonth = currentMonth + monthsToMove;

			if (nextOrPreviousMonth > 11) {
				year += 1;
				currentMonth = 0;
			} else if (nextOrPreviousMonth < 0) {
				year -= 1;
				currentMonth = 11;
			} else {
				currentMonth = nextOrPreviousMonth;
			}

			const firstOfMonth = new Date(`${year}/${currentMonth + 1}/01`);
			newDate = firstOfMonth;
		}

		setSelectedDay(newDate); // This will trigger a new calendar in the useEffect hook.
	};
	const toggleView = (_e, type) => {
		setZoom(false);
		setView(type);
	};
	const toggleHolidayPay = (e) => {
		if (typeof e.target.checked === "boolean") setHolidayPay(e.target.checked);
	};
	const togglePaidTimeOff = (e) => {
		if (typeof e.target.checked === "boolean") setPaidTimeOff(e.target.checked);
	};
	const toggleZoom = (e) => {
		if (e !== "") {
			e.stopPropagation();
			setZoom(!zoom);
		}
	};
	const CurrentCalendarDisplay = () => {
		return mode.id === "W" ? (
			<div className={`text-center w-[5rem] sm:w-32 h-full items-center ${zoom ? "flex my-2 w-fit" : ""}`}>
				<div
					className={`bg-white flex justify-center items-center ${
						zoom ? "sm:h-16 border-b px-1 rounded" : "sm:h-18 rounded-t "
					} border-pink-400 border-t border-r border-l`}
				>
					<span className={`text-3xl ${zoom ? "sm:text-5xl" : "sm:text-7xl"} font-bold text-gray-600`}>
						{firstDayOfWeek?.day?.padStart(2, "0")}
					</span>
				</div>
				<div className={`bg-pink-600 text-2xl sm:text-5xl text-white py-1 px-1 sm:h-16 ${zoom ? "rounded" : "rounded-b"}`}>
					{firstDayOfWeek?.monthShortDesc}
				</div>
			</div>
		) : width < 500 ? (
			<div className="text-center w-[5rem] sm:w-32 h-full">
				<div className="rounded-t bg-white  align-center sm:h-18 border-pink-400 border-t border-r border-l">
					<span className="ttext-3xl sm:text-7xl font-bold text-gray-600">
						{new Intl.DateTimeFormat("en", { month: "long" }).format(selectedDay)}
					</span>
				</div>
				<div className="bg-pink-600 text-2xl sm:text-5xl text-white py-1 sm:h-16 rounded-b">
					{new Intl.DateTimeFormat("en", { year: "numeric" }).format(selectedDay)}
				</div>
			</div>
		) : (
			<div className="text-center w-fit h-full">
				<div className="rounded-t bg-white w-56 px-3 py-3  border-pink-400 flex items-center justify-center border-t border-r border-l">
					<span className="text-3xl font-bold text-gray-600">{new Intl.DateTimeFormat("en", { month: "long" }).format(selectedDay)}</span>
				</div>
				<div className="bg-pink-600 text-5xl px-3 text-white py-3 rounded-b">
					{new Intl.DateTimeFormat("en", { year: "numeric" }).format(selectedDay)}
				</div>
			</div>
		);
	};
	const toggleLoading = (value) => {
		setIsLoading(value);
	};
	const handleTimeOff = () => {
		setTimeOffModuleOpen(true);
	};
	const filterShifts = async (e, typeId, type = "dept") => {
		if (type === "dept") setFilterByDepartment(typeId);
		if (type === "role") setFilterByRole(typeId);
	};
	const switchRateAndHour = (e) => {
		if (e !== "") e.stopPropagation();
		if (rateOrHour === "hour") setRateOrHour("rate");
		else if (rateOrHour === "rate") setRateOrHour("percentage");
		else if (rateOrHour === "percentage") setRateOrHour("hour");
	};
	const handleSaveTemplate = (e = "") => {
		if (e !== "") e.stopPropagation();
		setOverNoteParticularDayLayOpen(false);
		setOverLayRosterTemplateOpen(true);
		setOverLayOpen(true);
	};
	const handelExportHours = (e = "") => {
		if (e !== "") e.stopPropagation();
		setView("S");
		setConfirmHours(true);
	};
	const handlePublishShifts = async () => {
		setIsModalOpen(false);
		await publishShifts(); 
	};
const publishShifts = async () => {
	const unPublishedShifts = shift.filter((s) => s.isShiftPublished?.trim().toUpperCase() === "NOT_PUBLISH");

	if (unPublishedShifts.length === 0) {
		setMessage(
			showFailure({
				title: "No shifts to publish",
				subTitle: "There are no unpublished shifts at this time."
			})
		);
		return;
	}

	// Step 1: Filter members based on the department and unpublished shifts
	let memberIds = [];

	if (notifyAll) {
		// Notify all members who are part of the selected department (filterByDepartment)
		memberIds = organisation?.members
			?.filter((member) => member?.person) // Only members who accepted the invite
			?.filter((member) => {
				// Check if the member is in the selected department (filterByDepartment)
				const isInFilteredDepartment = filterByDepartment === "ALL" || member.departmentIDs.includes(filterByDepartment);

				return isInFilteredDepartment;
			})
			.map((member) => member.person); // Get their person ID
	} else {
		// Notify only members who have unpublished shifts and are in the selected department
		memberIds = organisation?.members
			?.filter((member) => member?.person) // Only members who accepted the invite
			?.filter((member) => {
				// Check if the member has unpublished shifts
				const hasUnpublishedShift = unPublishedShifts.some((shift) => shift.memberID === member.orgUserId);
				const isInFilteredDepartment = filterByDepartment === "ALL" || member.departmentIDs.includes(filterByDepartment);

				return hasUnpublishedShift && isInFilteredDepartment;
			})
			.map((member) => member.person); 
	}


	// If no valid members are found, stop here
	if (memberIds.length === 0) {
		setMessage(
			showFailure({
				title: "No valid members to notify",
				subTitle: "No members with unpublished shifts or valid invites."
			})
		);
		return;
	}

	try {
		await Promise.all(
			unPublishedShifts.map(async (shift) => {
				await orgUtils.publishShift(shift); // Assuming orgUtils.publishShift is the function to publish shifts
			})
		);

		await notifyUser(memberIds, `New shifts have been published to your roster`);

		buildCalendar();
		setMessage(
			showSuccess({
				title: "Shifts Published successfully."
			})
		);
	} catch (error) {
		setMessage(
			showFailure({
				title: "Shifts Not Published, Try Later",
				subTitle: error.message
			})
		);
	}
};
	const ModeOptions = () => {
		const radioClicked = (option) => {
			setMode(option);
		};
		return (
			<div>
				<label className="flex justify-center sm:justify-start font-medium text-gray-900">Calendar Mode</label>

				{width > 500 && !zoom && averageTaking?.target === 0 && (
					<p className="text-sm leading-5 text-gray-500">View an entire month or just focus on a particular week</p>
				)}
				<fieldset className="mt-2 sm:mt-4">
					{" "}
					<legend className="sr-only">Calendar Mode</legend>
					<div className=" flex justify-center sm:justify-start items-center space-x-10">
						{modes.map((modeOption) => (
							<div key={modeOption.id} className="flex flex-end">
								<input
									id={modeOption.id}
									name="calendar-mode"
									type="radio"
									onClick={() => radioClicked(modeOption)}
									defaultChecked={modeOption.id === mode.id}
									className="focus:ring-darkww-500 h-4 w-4 text-darkww-600 border-gray-300"
								/>
								<label htmlFor={modeOption.id} className="ml-3 block text-sm font-medium text-gray-700">
									{modeOption.description}
								</label>
							</div>
						))}
					</div>
				</fieldset>

				{person?.isAdmin || person?.assignedAdmin ? (
					<>
						<div className={`sm:flex justify-center sm:justify-start items-end mt-1 ${averageTaking?.target || zoom ? "sm:mt-0" : "sm:mt-3"}`}>
							{shift?.length === 0 && mode.id === "W" ? (
								<ShiftCopyButton
									organisation={organisation}
									buildCalendar={buildCalendar}
									selectedDay={selectedDay}
									setIsLoading={toggleLoading}
									person={person}
									filterByDepartment={filterByDepartment}
								/>
							) : (
								<>
									{/* {calendarDays.length > 0 && mode.id === "M" && (
                                        <ExportCsv calendarDays={calendarDays} activeDay={selectedDay} organisation={organisation} />
                                    )} */}
									<div
										className="bg-darkww-700 justify-center sm:justify-start shadow-sm rounded-md ml-1 sm:ml-0 px-1  w-full md:w-fit h-fit mt-2 sm:mt-2 flexcursor-pointer"
										onClick={(e) => switchRateAndHour(e)}>
										<div className="relative text-white mr-1 text-lg text-900 font-bold">
											{rateOrHour === "hour" ? (
												<HourView />
											) : rateOrHour === "rate" ? (
												<>
													<div className="flex mt-2">
														<div className="flex items-center my-0.5">
															{/* Using flexbox to keep items aligned */}
															<span className="text-sm pl-0.5 text-whiteww-900 ml-0.5 mr-1">Staff</span>
															<CalendarIcon className="h-4 w-4 mb-0.5 text-whiteww-900" />
															<span className="text-sm text-whiteww-900">:</span> {/* Separate span for the colon */}
														</div>
														<div className="flex justify-between w-full">
															<span className="text-sm pl-1 ml-1 text-whiteww-900 font-normal mr-1">
																{`Closed: €${Number(holidayPay ? 1.08 * averageTaking?.earningsToDate : averageTaking?.earningsToDate)
																	?.toFixed(0)
																	.replace(/[.,]00$/, "")}`}
															</span>
															<span className="flex text-sm text-whiteww-900 font-normal justify-end">
																{`\u00A0 ${mode.id === "W" ? "Week:" : "Month:"} €${Number(holidayPay ? 1.08 * totalRate : totalRate)
																	?.toFixed(0)
																	.replace(/[.,]00$/, "")}`}
															</span>
														</div>
													</div>

													<div className="flex">
														<div className="flex items-center my-0.5">
															{/* Using flexbox to keep items aligned */}
															<span className="text-sm pl-0.5 text-whiteww-900 ml-0.5 mr-1">Staff</span>
															<ClockIcon className="h-4 w-4 mb-0.5 text-whiteww-900" />
															<span className="text-sm text-whiteww-900">:</span> {/* Separate span for the colon */}
														</div>
														<div className="flex justify-between w-full">
															<span className="text-sm pl-0 text-whiteww-900 font-normal">
																{`\u00A0 Closed: €${Number(holidayPay ? 1.08 * clockedTotalRate : clockedTotalRate)
																	?.toFixed(0)
																	.replace(/[.,]00$/, "")}`}
															</span>
															<span className="flex text-sm text-whiteww-900 font-normal justify-end">
																{`\u00A0 Live: €${Number(holidayPay ? 1.08 * weeklyLiveCost : weeklyLiveCost)
																	?.toFixed(0)
																	.replace(/[.,]00$/, "")}`}
															</span>
														</div>
													</div>
													{averageTaking?.target ? (
														<div className="text-sm text-whiteww-900 font-normal flex justify-between">
															<span className="text-sm pl-1 text-whiteww-900 font-bold mr-1.5 w-16">Target :</span>

															{`Closed: €${averageTaking?.targetToDate.toFixed(0)}  \u00A0\u00A0  ${
																mode.id === "W" ? "Week:" : "Month:"
															} €${averageTaking?.target.toFixed(0)}`}
														</div>
													) : null}

													{averageTaking?.actual > 0 && !isNaN(averageTaking?.actual) && (
														<div className="text-sm mt-1 pl-1 text-whiteww-900 font-normal flex flex-row items-center justify-between">
															<div>
																Take: €<span className="text-md text-whiteww-900">{averageTaking?.actual.toFixed(0)}</span>
																{/* Display total without VAT */}
																{organisation?.vat === true && (
																	<div className="text-sm pl-1 text-whiteww-900 font-normal">
																		(-VAT) €
																		<span className="text-md pr-1 text-whiteww-900">{(averageTaking?.food + averageTaking?.drinks).toFixed(0)}</span>
																	</div>
																)}
															</div>

															{averageTaking?.food > 0 ? (
																<div className="flex flex-row">
																	<FoodIcon className="w-6 h-6 pl-1 pr-1" fill="currentColor" />:{" "}
																	<span className="text-md pl-1 pr-1 text-whiteww-900">€{averageTaking?.food.toFixed(0)}</span>
																</div>
															) : null}
															{averageTaking?.drinks > 0 ? (
																<div className="flex flex-row">
																	<MartiniIcon
																		className="w-4 h-4"
																		fill="currentColor"
																		stroke="#fff"
																		strokeWidth="2"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>{" "}
																	: <span className="text-md pl-1 text-whiteww-900">€{averageTaking?.drinks.toFixed(0)}</span>
																</div>
															) : null}
														</div>
													)}
												</>
											) : rateOrHour === "percentage" ? (
												<PercentageView />
											) : (
												<></>
											)}
										</div>
									</div>
									{mode.id === "M" && <SetTimeOffButton handleTimeOff={handleTimeOff} />}
									<div className="flex flex-row justify-center items-end">
										{width < 500 && mode.id === "W" && (
											<>
												{(person?.isAdmin || person?.assignedAdmin) && (
													<Menu as="div" className="sm:mx-0 w-fit font-medium copy-week z-50">
														{({ open }) => (
															<>
																<Menu.Button className="px-1 py-1 text-md bg-darkww-700 mr-2 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 p-1 rounded-md">
																	<span className="ml-3 text-gray-700 text-sm font-medium block text-white">Dept</span>
																	<ChevronDownIcon className="flex-shrink-0 ml-1 h-5 w-3 text-white sm:block" aria-hidden="true" />
																</Menu.Button>
																<Transitions
																	show={open}
																	as={Fragment}
																	enter="transition ease-out duration-100"
																	enterFrom="transform opacity-0 scale-95"
																	enterTo="transform opacity-100 scale-100"
																	leave="transition ease-in duration-75"
																	leaveFrom="transform opacity-100 scale-100"
																	leaveTo="transform opacity-0 scale-95">
																	<Menu.Items
																		static
																		className="fixed mt-1 w-38 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
																		<div className="sm:block">
																			<Menu.Item>
																				<div
																					className={`block px-4 py-2 text-sm cursor-pointer ${
																						filterByDepartment === "ALL" ? "bg-lightww-600 text-white" : "text-gray-700"
																					}`}
																					onClick={(e) => filterShifts(e, "ALL")}>
																					All
																				</div>
																			</Menu.Item>
																			{organisation?.departments?.map((d) => (
																				<Menu.Item key={d.id}>
																					<div
																						className={`block px-4 py-2 text-sm cursor-pointer ${
																							filterByDepartment === d.id ? "bg-lightww-600 text-white" : "text-gray-700 "
																						}`}
																						onClick={(e) => filterShifts(e, d.id)}>
																						{d.name}
																					</div>
																				</Menu.Item>
																			))}
																		</div>
																	</Menu.Items>
																</Transitions>
															</>
														)}
													</Menu>
												)}

												<span>
													{view === "S" && (
														<p className="relative sm:absolute text-darkww-800 sm:right-6">
															{zoom ? (
																<ZoomInIcon className="h-6 w-6" onClick={(e) => toggleZoom(e)} />
															) : (
																<ZoomOutIcon className="h-6 w-6" onClick={(e) => toggleZoom(e)} />
															)}
														</p>
													)}
												</span>
												<div>
													{/* <Button
                                                        label="View"
                                                        onClick={(e) => toggleView(e)}
                                                        className="rounded-md shadow-sm text-sm font-medium text-white bg-darkww-700 hover:bg-darkww-600 shadow-sm mt-2 px-auto mr-2 bottom-0 left-0 py-1 px-4 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 m-auto"
                                                    /> */}
													<Menu as="div" className="sm:mx-0 w-fit font-medium copy-week z-50">
														{({ open }) => (
															<>
																<Menu.Button className="px-1 py-1 text-md bg-darkww-700 mr-2 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 p-1 rounded-md">
																	<span className="ml-3 text-gray-700 text-sm font-medium block text-white">View</span>
																	<ChevronDownIcon className="flex-shrink-0 ml-1 h-5 w-3 text-white sm:block" aria-hidden="true" />
																</Menu.Button>
																<Transitions
																	show={open}
																	as={Fragment}
																	enter="transition ease-out duration-100"
																	enterFrom="transform opacity-0 scale-95"
																	enterTo="transform opacity-100 scale-100"
																	leave="transition ease-in duration-75"
																	leaveFrom="transform opacity-100 scale-100"
																	leaveTo="transform opacity-0 scale-95">
																	<Menu.Items
																		static
																		className="fixed mt-1 w-38 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
																		<div className="sm:block z-50">
																			<Menu.Item>
																				<div
																					className={`block px-4 pt-2 pb-1 text-sm cursor-pointer ${
																						view === "S" ? "bg-lightww-600 text-white" : "text-gray-700"
																					}`}
																					onClick={(e) => toggleView(e, "S")}>
																					By Employee
																					<div className="text-[10px]">(Drag & Drop)</div>
																				</div>
																			</Menu.Item>
																		</div>
																		<div className="sm:block">
																			<Menu.Item>
																				<div
																					className={`block px-4 py-2 text-sm cursor-pointer ${
																						view === "C" && !sortByRole ? "bg-lightww-600 text-white" : "text-gray-700"
																					}`}
																					onClick={(e) => {
																						toggleView(e, "C");
																						setSortByRole(false);
																					}}>
																					By Start Time
																				</div>
																			</Menu.Item>
																		</div>
																		<div className="sm:block">
																			<Menu.Item>
																				<div
																					className={`block px-4 pb-2 pt-1 text-sm cursor-pointer ${
																						view === "C" && sortByRole ? "bg-lightww-600 text-white" : "text-gray-700"
																					}`}
																					onClick={(e) => {
																						toggleView(e, "C");
																						setSortByRole(true);
																					}}>
																					By Role
																				</div>
																			</Menu.Item>
																		</div>
																	</Menu.Items>
																</Transitions>
															</>
														)}
													</Menu>
												</div>
												{(person?.isAdmin || person?.assignedAdmin) && (
													<div className="flex justify-center top-2 left-4 absolute  bottom-16 left-0 py-1 px-1 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 m-auto top-0">
														<div className="form-check form-switch flex items-start">
															<span className="flex items-center ">
																<input
																	id="link-checkbox"
																	type="checkbox"
																	checked={!!holidayPay}
																	onChange={(e) => toggleHolidayPay(e)}
																	className="w-4 h-4 text-darkww-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-300"
																/>
																<label htmlFor="link-checkbox" className="ml-2 text-sm font-medium text-gray-800">
																	Hol. Pay @ 8%
																</label>
															</span>
															<span className="flex items-center ml-8">
																<input
																	id="link-checkbox"
																	type="checkbox"
																	checked={!!paidTimeOff}
																	onChange={(e) => togglePaidTimeOff(e)}
																	className="w-4 h-4 text-darkww-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-300"
																/>
																<label htmlFor="link-checkbox" className="ml-2 text-sm font-medium text-gray-800">
																	incl. Paid Days
																</label>
															</span>
														</div>
													</div>
												)}
											</>
										)}
										<Menu as="div" className="mt-1 w-fit font-medium copy-week">
											{({ open }) => (
												<>
													<Menu.Button className="px-1 py-1 text-md bg-darkww-700 mr-2 ml-1 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 p-1 rounded-md">
														<span className="ml-3 text-gray-700 text-sm font-medium block text-white">Save</span>
														<ChevronDownIcon className="flex-shrink-0 ml-1 h-5 w-3 text-white sm:block" aria-hidden="true" />
													</Menu.Button>
													<Transitions
														show={open}
														as={Fragment}
														enter="transition ease-out duration-100"
														enterFrom="transform opacity-0 scale-95"
														enterTo="transform opacity-100 scale-100"
														leave="transition ease-in duration-75"
														leaveFrom="transform opacity-100 scale-100"
														leaveTo="transform opacity-0 scale-95">
														<Menu.Items
															static
															className="absolute mt-2 w-38 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
															<div className="sm:block">
																<Menu.Item>
																	<div className="block px-4 py-2 text-sm text-gray-700 cursor-pointer" onClick={(e) => handleSaveTemplate(e)}>
																		Save As Template
																	</div>
																</Menu.Item>
																<Menu.Item>
																	<div className="block px-4 py-2 text-sm text-gray-700 cursor-pointer" onClick={(e) => handelExportHours(e)}>
																		Export Hours
																	</div>
																</Menu.Item>
																<Menu.Item>
																	<div className="block px-4 py-2 text-sm text-gray-700 cursor-pointer" onClick={() => setIsModalOpen(true)}>
																		Publish
																	</div>
																</Menu.Item>

																<Menu.Item>
																	<div className="block px-4 py-2 text-sm text-gray-700 cursor-pointer" onClick={(e) => handlePrint(e)}>
																		Print
																	</div>
																</Menu.Item>
															</div>
														</Menu.Items>
													</Transitions>
												</>
											)}
										</Menu>
										{/* Modal for Notify Options */}
										<Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
											<div className="fixed z-10 inset-0 overflow-y-auto">
												<div className="flex items-center justify-center min-h-screen">
													<div className="bg-white p-6 rounded-lg shadow-lg">
														<h2 className="text-lg font-medium text-gray-900">Publish Shifts</h2>
														<p className="mt-2 text-sm text-gray-600">Who would you like to notify?</p>
														<div className="mt-4">
															<label className="flex items-center mb-2">
																<input
																	type="radio"
																	name="notifyOption"
																	checked={!notifyAll}
																	onChange={() => setNotifyAll(false)}
																	className="form-radio h-4 w-4 text-darkww-600"
																/>
																<span className="ml-2 text-sm">Only selected department members with updated shifts</span>
															</label>
															<label className="flex items-center">
																<input
																	type="radio"
																	name="notifyOption"
																	checked={notifyAll}
																	onChange={() => setNotifyAll(true)}
																	className="form-radio h-4 w-4 text-darkww-600"
																/>
																<span className="ml-2 text-sm">All members in the selected department</span>
															</label>
														</div>
														<div className="mt-6 flex justify-end">
															<button
																onClick={() => setIsModalOpen(false)}
																className="px-4 py-2 mr-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-100">
																Cancel
															</button>
															<button onClick={handlePublishShifts} className="px-4 py-2 bg-darkww-700 text-white rounded-md hover:bg-darkww-600">
																Publish
															</button>
														</div>
													</div>
												</div>
											</div>
										</Dialog>

										{(person?.isAdmin || person?.assignedAdmin) && width < 500 && lateClockOutData && <NotificationButton />}
									</div>
								</>
							)}
						</div>
					</>
				) : (
					mode.id === "M" && <SetTimeOffButton handleTimeOff={handleTimeOff} />
				)}
			</div>
		);
	};

	return width < 500 ? (
		<div className="col-span-7 flex justify-center flex-nowrap row-span-1 shadow-lg border-2  border-gray-300 rounded-lg p-3">
			<div className="flex justify-center sm:justify-start">
				<div className="flex-1  hidden 2xl:block"></div>
				<div className="flex-1 min-w-fit mx-auto">
					<div className="flex flex-row  items-center justify-center">
						<button type="button" onClick={() => changeCalendar("DOWN")}>
							<ArrowCircleLeftIcon className="h-20 w-20 text-gray-500 hover:text-darkww-600" aria-hidden="true" />
						</button>
						<div className="mx-4">
							<CurrentCalendarDisplay />
						</div>
						<button type="button" onClick={() => changeCalendar("UP")}>
							<ArrowCircleRightIcon className="h-20 w-20 text-gray-500 hover:text-darkww-600" aria-hidden="true" />
						</button>
					</div>
					<div className="flex grid mt-4 justify-center content-between items-center ">
						<ModeOptions />
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className="col-span-7 row-span-1 shadow-lg border-2 border-gray-300 rounded-lg h-fit p-3">
			<div className="flex flex-row relative content-between h-fit pr-2">
				<div className="flex-1 h-full hidden 2xl:block"></div>
				<div className="flex-1 min-w-fit h-fit mx-auto">
					<div className="flex flex-row h-fit items-center justify-center">
						<div className="flex flex-row">
							<button type="button" onClick={() => changeCalendar("DOWN")}>
								<ArrowCircleLeftIcon className="h-20 w-20 text-gray-500 hover:text-darkww-600" aria-hidden="true" />
							</button>
							<div className={`${zoom ? "mr-6 mb-4" : "mx-4"}`}>
								<CurrentCalendarDisplay />
							</div>
							<button type="button" onClick={() => changeCalendar("UP")}>
								<ArrowCircleRightIcon className="h-20 w-20 text-gray-500 hover:text-darkww-600" aria-hidden="true" />
							</button>
						</div>
						{width > 500 && (
							<div>
								{mode.id === "W" && (
									<div className="flex">
										{(person?.isAdmin || person?.assignedAdmin) &&
											organisation?.departments &&
											organisation?.departments?.constructor === Array &&
											organisation?.departments?.length > 0 && (
												<Menu
													as="div"
													className="sm:mx-0 bg-darkww-700 hover:bg-darkww-600 absolute bottom-8 left-0 rounded-md shadow-sm text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 w-fit font-medium"
												>
													{({ open }) => (
														<>
															<Menu.Button className="px-1 py-1 text-md bg-darkww-700 mr-1 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 p-1 rounded-md">
																<span className="ml-1 text-gray-700 text-sm font-medium block text-white">Dept</span>
																<ChevronDownIcon
																	className="flex-shrink-0 ml-1 h-5 w-3 text-white sm:block"
																	aria-hidden="true"
																/>
															</Menu.Button>
															<Transitions
																show={open}
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95"
															>
																<Menu.Items
																	static
																	className="absolute mt-2 w-max rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
																>
																	<div className="sm:block">
																		<Menu.Item>
																			<div
																				className={`block px-4 py-2 text-sm cursor-pointer ${
																					filterByDepartment === "ALL"
																						? "bg-lightww-600 text-white"
																						: "text-gray-700"
																				}`}
																				onClick={(e) => filterShifts(e, "ALL")}
																			>
																				All
																			</div>
																		</Menu.Item>
																		{organisation?.departments?.map((d) => (
																			<Menu.Item key={d.id}>
																				<div
																					className={`block px-4 py-2 text-sm cursor-pointer ${
																						filterByDepartment === d.id
																							? "bg-lightww-600 text-white"
																							: "text-gray-700 "
																					}`}
																					onClick={(e) => filterShifts(e, d.id)}
																				>
																					{d.name}
																				</div>
																			</Menu.Item>
																		))}
																	</div>
																</Menu.Items>
															</Transitions>
														</>
													)}
												</Menu>
											)}

										<div className="flex inline-flex ">
											{/* <Button
                                            label="View"
                                            
                                            className="rounded-md shadow-sm text-sm font-medium text-white bg-darkww-700 hover:bg-darkww-600 shadow-sm w-fit mt-2 px-auto w-full absolute bottom-0 left-0 py-1 px-4 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 m-auto"
                                        /> */}
											<Menu
												as="div"
												className="sm:mx-0 bg-darkww-700 hover:bg-darkww-600 absolute bottom-0 left-0 rounded-md shadow-sm text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 w-fit font-medium"
											>
												{({ open }) => (
													<>
														<Menu.Button className="px-1 py-1 text-md bg-darkww-700 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 p-1 rounded-md">
															<span className="ml-1 text-gray-700 text-sm font-medium block text-white">View</span>
															<ChevronDownIcon
																className="flex-shrink-0 mx-1 h-5 w-3 text-white sm:block"
																aria-hidden="true"
															/>
														</Menu.Button>
														<Transitions
															show={open}
															as={Fragment}
															enter="transition ease-out duration-100"
															enterFrom="transform opacity-0 scale-95"
															enterTo="transform opacity-100 scale-100"
															leave="transition ease-in duration-75"
															leaveFrom="transform opacity-100 scale-100"
															leaveTo="transform opacity-0 scale-95"
														>
															<Menu.Items
																static
																className="absolute mt-1 w-max rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
															>
																<div className="sm:block z-50">
																	<Menu.Item>
																		<div
																			className={`block px-4 py-2 text-sm cursor-pointer ${
																				view === "C" && !sortByRole
																					? "bg-lightww-600 text-white"
																					: "text-gray-700"
																			}`}
																			onClick={(e) => {
																				toggleView(e, "C");
																				setSortByRole(false);
																			}}
																		>
																			By Start Time
																		</div>
																	</Menu.Item>
																</div>
																<div className="sm:block">
																	<Menu.Item>
																		<div
																			className={`block px-4 pt-2 pb-1 text-sm cursor-pointer ${
																				view === "S" ? "bg-lightww-600 text-white" : "text-gray-700"
																			}`}
																			onClick={(e) => toggleView(e, "S")}
																		>
																			By Employee
																			<div className="text-[10px]">(Drag & Drop)</div>
																		</div>
																	</Menu.Item>
																</div>
																<div className="sm:block">
																	<Menu.Item>
																		<div
																			className={`block px-4 pb-2 pt-1 text-sm cursor-pointer ${
																				view === "C" && sortByRole
																					? "bg-lightww-600 text-white"
																					: "text-gray-700"
																			}`}
																			onClick={(e) => {
																				toggleView(e, "C");
																				setSortByRole(true);
																			}}
																		>
																			By Role
																		</div>
																	</Menu.Item>
																</div>
															</Menu.Items>
														</Transitions>
													</>
												)}
											</Menu>
											{view === "S" && (
												<p className="text-darkww-800 ml-1 mt-2 absolute bottom-0 sm:left-12 py-1 px-4 m-auto">
													{zoom ? (
														<ZoomInIcon className="h-5 w-5" onClick={(e) => toggleZoom(e)} />
													) : (
														<ZoomOutIcon className="h-5 w-5" onClick={(e) => toggleZoom(e)} />
													)}
												</p>
											)}
										</div>
										{(person?.isAdmin || person?.assignedAdmin) && (
											<>
												<div className="flex justify-center  absolute bottom-16 left-0 py-1 px-1 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 m-auto top-0">
													<div className="form-check form-switch">
														<div className="flex items-center">
															<input
																id="link-checkbox"
																type="checkbox"
																checked={!!holidayPay}
																onChange={(e) => toggleHolidayPay(e)}
																className="w-4 h-4 text-darkww-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-300"
															/>
															<label htmlFor="link-checkbox" className="ml-2 text-sm font-medium text-gray-800">
																Hol. Pay @ 8%
															</label>
														</div>
														<div className="flex items-center ">
															<input
																id="link-checkbox"
																type="checkbox"
																checked={!!paidTimeOff}
																onChange={(e) => togglePaidTimeOff(e)}
																className="w-4 h-4 text-darkww-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-300"
															/>
															<label htmlFor="link-checkbox" className="ml-2 text-sm font-medium text-gray-800">
																incl. Paid Days
															</label>
														</div>
													</div>
												</div>
											</>
										)}
									</div>
								)}
							</div>
						)}
					</div>
				</div>
				<div className="flex-1 grid mt-2 justify-center sm:justify-start content-between items-end">
					<ModeOptions />
				</div>
				{(person?.isAdmin || person?.assignedAdmin) && <NotificationButton />}
			</div>
		</div>
	);
};

export default HeaderControlPanel;

const HourView = () => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const { mode, totalHours, averageTaking, holidayPay } = useContext(CalendarDataContext);
	return (
		<>
			<span className="text-lg pl-1 text-whiteww-900 font-bold mr-1">{mode.description}</span>
			<span className="text-sm text-whiteww-900 font-normal mr-1">Total</span>
			{Number(holidayPay ? 1.08 * totalHours : totalHours)
				?.toFixed(2)
				.replace(/[.,]00$/, "")}
			<span className="text-sm pl-1 text-whiteww-900 font-normal">hrs </span>
			{averageTaking?.actual > 0 && !isNaN(averageTaking?.actual) && !(person?.assignedAdmin && !person?.assignedFinancials) && (
				<div className="text-sm pl-1 text-whiteww-900 font-normal flex flex-row items-center">
					Take: €<span className="text-md text-whiteww-900">{(averageTaking?.actual).toFixed(0)}</span>
					{/* Display total without VAT */}
					{organisation?.vat === true && (
						<div className="text-sm pl-1 pr-1 text-whiteww-900 font-normal">
							(-VAT) €<span className="text-md pr-1 text-whiteww-900">{(averageTaking?.food + averageTaking?.drinks).toFixed(0)}</span>
						</div>
					)}
					{averageTaking?.food > 0 ? (
						<>
							<FoodIcon className="w-6 h-6 pl-1 pr-1" fill="currentColor" /> €
							<span className="text-md pr-1 text-whiteww-900">{(averageTaking?.food).toFixed(0)}</span>
						</>
					) : null}
					{averageTaking?.drinks > 0 ? (
						<>
							<MartiniIcon
								className="w-4 h-4"
								fill="currentColor"
								stroke="#fff"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>{" "}
							€<span className="text-md text-whiteww-900">{(averageTaking?.drinks).toFixed(0)}</span>
						</>
					) : null}
				</div>
			)}
		</>
	);
};

const PercentageView = () => {
	const { organisation } = useContext(OrganisationContext);
	const { totalRate, averageTaking, holidayPay } = useContext(CalendarDataContext);
	if (!organisation.StaffCost) {
		return <span className="text-sm pl-1 text-whiteww-900 font-normal">Please Add Staff % in General Setting</span>;
	}
	return (
		<div>
			{averageTaking?.target ? (
				<div>
					<div className="flex underline justify-start text-sm pl-1 text-whiteww-900 font-normal">Based on Staff Cost of Report Days:</div>

					<div>
						<TargetIcon className=" inline-block h-5 w-5 my-0.5 mb-1 ml-1 text-white" />
						<span className=" inline text-sm pl-1 text-whiteww-900 font-normal">
							Closed:
							<span className=" inline text-md pl-1 text-whiteww-900 font-bold">
								{Number(
									((holidayPay ? 1.08 * averageTaking?.earningsToDate : averageTaking?.earningsToDate) /
										averageTaking?.targetToDate) *
										100
								).toFixed(1)}
								%
							</span>
						</span>
						<span className=" inline text-sm pl-1 text-whiteww-900 font-normal">
							All Week:
							<span className=" inline text-md pl-1 text-whiteww-900 font-bold">
								{Number(((holidayPay ? 1.08 * totalRate : totalRate) / averageTaking?.target) * 100).toFixed(1)}%
							</span>
						</span>
					</div>
				</div>
			) : (
				<div className="text-sm px-1 text-whiteww-900 font-normal">
					{`Staff Cost V. ${organisation.StaffCost}% avg week:`}
					<span className="text-lg pl-1 text-whiteww-900 font-bold">
						€{Math.round(averageTaking?.takings * (organisation.StaffCost / 100) - (holidayPay ? 1.08 * totalRate : totalRate), 1)}
					</span>
				</div>
			)}
			{averageTaking?.actual > 0 && !isNaN(averageTaking?.actual) && (
				<div className="text-sm pl-1 text-whiteww-900 font-normal flex flex-row items-center">
					<div className="text-sm pr-1 text-whiteww-900 font-normal">
						Take:
						<span className="text-md pl-1 text-whiteww-900 mr-1">
							{Number(
								((holidayPay ? 1.08 * averageTaking?.earningsToDate : averageTaking?.earningsToDate) / averageTaking?.actual) * 100
							).toFixed(1)}
							%
						</span>
						{/* Display total without VAT for the Take value */}
						{organisation?.vat === true && (
							<>
								(-VAT):
								<span className="text-md pl-1 text-whiteww-900">
									{Number(
										((holidayPay ? 1.08 * averageTaking?.earningsToDate : averageTaking?.earningsToDate) /
											(averageTaking?.food + averageTaking?.drinks)) *
											100
									).toFixed(1)}
									%
								</span>
							</>
						)}
					</div>

					{averageTaking?.food && averageTaking?.food > 0 ? (
						<>
							<FoodIcon className="w-6 h-6 pl-1 pr-1" fill="currentColor" /> :{" "}
							<span className="text-md pl-1 pr-1 text-whiteww-900">
								{Number(
									((holidayPay ? 1.08 * averageTaking?.earningsToDate : averageTaking?.earningsToDate) / averageTaking?.food) * 100
								).toFixed(1)}
								%
							</span>
						</>
					) : null}
					{averageTaking?.drinks && averageTaking?.drinks > 0 ? (
						<>
							<MartiniIcon
								className="w-4 h-4"
								fill="currentColor"
								stroke="#fff"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							:{" "}
							<span className="text-md pl-1 text-whiteww-900">
								{Number(
									((holidayPay ? 1.08 * averageTaking?.earningsToDate : averageTaking?.earningsToDate) / averageTaking?.drinks) *
										100
								).toFixed(1)}
								%
							</span>{" "}
						</>
					) : null}
				</div>
			)}
		</div>
	);
};

const SetTimeOffButton = ({ handleTimeOff }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	if (!(organisation?.enableStaffTimeOff ?? true) && !(person?.isAdmin || person?.assignedAdmin)) return null;
	return (
		<button
			className="flex inline-flex justify-center sm:justify-start ml-1 items-center bg-darkww-700 shadow-sm rounded-md w-full sm:w-fit mt-2 px-2"
			onClick={(e) => handleTimeOff(e)}
		>
			<span className="text-white text-lg text-900 ">Time Off</span>{" "}
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="ml-2 h-5 w-5  text-lg text-900"
				style={{ color: "red" }}
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				strokeWidth="3"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
				/>
			</svg>
		</button>
	);
};

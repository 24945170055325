import { CalendarIcon, RefreshIcon, TrendingDownIcon, TrendingUpIcon } from "@heroicons/react/outline";
import React, { Fragment, useContext, useState } from "react";
import { arrayDiff, dateMeta, formateCurrency, notifyUser, showFailure, useSimpleMessage } from "ww-framework";
import { CalendarDataContext, OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";
// import ExportCsv from "./ExportCsv";
// import { MemberListItem } from "./ShiftForm";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { FoodIcon, MartiniIcon } from "../../../ww-images/MartiniIcon";
import { TargetIcon } from "../../../ww-images/TargetIcon";
import ExtraPay from "../ExtraPay";
import { calculateLiveActualEarnings, calculateLiveRosteredCost, calculateTotalEarnings, calculateTotalTime } from "./Calendar";
import EmployeeViewStaffList from "./EmployeeViewStaffList";
import MultipleShiftCard from "./MultipleShiftCard";
import { Preferred } from "./Preferred";
import { SmallShiftCard } from "./SmallShiftCard";
import { SmallShiftCardWithoutMemberName } from "./SmallShiftCardWithoutMemberName";
import { getTimeOffData } from "./calendar_utils";

const DayCards = ({ getPreferences, getExtraPay, getTimeOff }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const [member] = useState(organisation.members?.find((m) => m.person === person.person));
	const { setMessage } = useSimpleMessage();
	const {
		selectedDay,
		activeDay,
		shift,
		setShift,
		calendarDays,
		setCalendarDays,
		mode,
		view,
		rateOrHour,
		preferenceData,
		timeOff,
		paidTimeOff,
		noteParticularDay,
		filterByDepartment,
		filterByRole,
		zoom,
		width,
		extraPays,
		holidayPay,
		punchData,
		sortByRole,
		setActiveDay,
		setEditShift,
		setOverLayOpen,
		setModuleType,
		setChooseModuleOpen,
		setChooseModuleType,
		setOverNoteParticularDayLayOpen,
		setOverLayRosterTemplateOpen,
		setNoteParticularDayData,
		isDraggingOver,
		setTotalHours,
		setTotalRate,
		setAverageTaking,
		setIsLoading
	} = useContext(CalendarDataContext);

	const showChooseModelPopup = (currentDay, shift = "", e = "", type = "") => {
		if (e !== "") e.stopPropagation();
		if (!(person?.isAdmin || person?.assignedAdmin) && shift === "") return null;
		setActiveDay(currentDay);
		setEditShift(shift);
		setOverNoteParticularDayLayOpen(false);
		setOverLayRosterTemplateOpen(false);
		if (person?.isAdmin || person?.assignedAdmin) setChooseModuleOpen(true);
		else if (shift.memberID === member?.orgUserId && shift.shiftStart < new Date(new Date().toUTCString()).valueOf() / 1000) {
			setChooseModuleType("checkInCheckOut");
			setChooseModuleOpen(true);
		} else if (width > 50) {
			setOverLayOpen(true);
			setModuleType(type);
		}
	};

	const clickOrDrag = (p, e, currentDay) => {
		if (!p?.isDraggingOver) pickNewDay(currentDay, "", e);
	};

	const getDragStyle = (isDragging, draggableStyle, zoom) => {
		if (isDragging && draggableStyle.transform) {
			const transformValues = draggableStyle.transform.match(/-?\d+(\.\d+)?/g);
			const x = parseFloat(transformValues[0]);
			const y = parseFloat(transformValues[1]);

			const mouseYOffset = zoom ? 120 : 40; // Adjust this value to match the desired distance between the card and the mouse pointer
			const mouseXOffset = 0; // You can also adjust this value if you want to change the horizontal distance

			const newY = y - mouseYOffset;
			const newX = x - mouseXOffset;

			return {
				background: "lightgreen",
				...draggableStyle,
				transform: `translate(${newX}px, ${newY}px)`,
				className: "dragging",
				width: zoom ? 145 : 170
			};
		}

		return {
			background: isDragging ? "purple" : "",
			...draggableStyle,
			transform: isDraggingOver ? draggableStyle.transform : ""
		};
	};

	const removeShift = (event, day, shiftToRemove, filterByDepartment) => {
		event.stopPropagation(); // Prevents the click event from bubbling up

		if (!shiftToRemove.memberID) {
			setCalendarDays((prevDays) => {
				return prevDays.map((currentDay) => {
					if (currentDay.baseEpoch === day.baseEpoch) {
						currentDay.shifts = currentDay.shifts.filter((shift) => shift.id !== shiftToRemove.id);
						currentDay.allUnfilteredShift = currentDay.allUnfilteredShift.filter((shift) => shift.id !== shiftToRemove.id);
					}
					return currentDay;
				});
			});

			// Update the shifts state to reflect the removed shift
			setShift((prevShifts) => {
				return prevShifts.filter((shift) => shift.id !== shiftToRemove.id);
			});
			return;
		}
		// Find the member associated with the shift
		const member = organisation.members.find((m) => m.orgUserId === shiftToRemove.memberID);

		// Check if the shift's member belongs to the currently selected department/role
		if (
			(filterByDepartment === "ALL" || (member && member.departmentIDs.includes(filterByDepartment))) &&
			(filterByRole === "ALL" || shiftToRemove.roleID === filterByRole)
		) {
			// Remove the shift from calendarDays (shifts and allUnfilteredShift)
			setCalendarDays((prevDays) => {
				return prevDays.map((currentDay) => {
					if (currentDay.baseEpoch === day.baseEpoch) {
						currentDay.shifts = currentDay.shifts.filter((shift) => shift.id !== shiftToRemove.id);
						currentDay.allUnfilteredShift = currentDay.allUnfilteredShift.filter((shift) => shift.id !== shiftToRemove.id);
					}
					return currentDay;
				});
			});

			// Update the shifts state to reflect the removed shift
			setShift((prevShifts) => {
				return prevShifts.filter((shift) => shift.id !== shiftToRemove.id);
			});

			// Calculate the cost of the removed shift
			const h =
				(shiftToRemove.shiftEnd -
					shiftToRemove.shiftStart -
					(shiftToRemove.unPaidBreak &&
						(shiftToRemove.breakDuration ? shiftToRemove.breakDuration * 60 : shiftToRemove.breakEnd - shiftToRemove.breakStart))) /
				3600;
			const e = shiftToRemove.member.empRateUnit === "YEARLY" ? shiftToRemove.member.huorlyRate / 260 : h * shiftToRemove.member.huorlyRate;

			// Subtract this cost from the totals
			setTotalHours((prevTotalHours) => parseFloat(prevTotalHours) - h);
			setTotalRate((prevTotalRate) => parseFloat(prevTotalRate) - e);

			// Check if the day of the removed shift has a report with takings
			const dayReport = day.report && day.report.length > 0 && day.report[0].takings && parseFloat(day.report[0].takings) !== 0;

			if (dayReport) {
				setAverageTaking((prevAverage) => ({
					...prevAverage,
					earningsToDate: prevAverage.earningsToDate - e
				}));
			}
		}
	};

	const addNoteParticularDay = (e = "", currentDay, data) => {
		if (e !== "") e.stopPropagation();
		setActiveDay(currentDay);
		setOverNoteParticularDayLayOpen(true);
		setOverLayRosterTemplateOpen(false);
		setNoteParticularDayData(data);
		setOverLayOpen(true);
	};

	const approveTimeOff = async (e, id, status = "APPROVE", person = null) => {
		try {
			if (e !== "") e.stopPropagation();
			setIsLoading(true);
			await orgUtils.changeStatusTimeOff(id, status);
			person && (await notifyUser([person], `Your Time Off request has been approved by your manager`));
			await getTimeOff();
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to change preference.",
					subTitle: error.message
				})
			);
		}
	};

	const pickNewDay = (currentDay, shift = "", e = "", type = "") => {
		if (e !== "") e.stopPropagation();
		if (!(person?.isAdmin || person?.assignedAdmin) && shift === "") return null;
		setActiveDay(currentDay);
		setEditShift(shift);
		setModuleType(type);
		setOverLayOpen(true);
		setOverNoteParticularDayLayOpen(false);
		setOverLayRosterTemplateOpen(false);
	};

	const preferenceSave = async (currentDay, status, id = "", repeat = false, selectedEndDate = null, preferenceNote) => {
		let activeOrg = window.sessionStorage.getItem("activeOrg") ?? 0;

		try {
			setIsLoading(true);
			if (status === "DELETE" && id !== "" && !repeat) {
				//repeated delete preferences are handled by the savePreference function
				await orgUtils.deletePreference(id);
			} else {
				await orgUtils.savePreference({
					id: id ?? "",
					organisationID: organisation.id,
					preferenceType: status,
					preferenceDate: currentDay.baseEpoch,
					memberID: person?.organisations?.items[activeOrg].id,
					repeat: repeat,
					repeatEndTimestamp: selectedEndDate ? dateMeta(selectedEndDate).baseEpoch : null,
					preferenceNote
				});
			}
			await getPreferences();
			setIsLoading(false);
		} catch (error) {
			setMessage(
				showFailure({
					title: "Unable to change preference.",
					subTitle: error.message
				})
			);
		}
	};

	const currentMonth = selectedDay.getMonth() + 1;
	if (mode.id === "W" && view === "S") {
		// Employee VIEW
		return calendarDays.map((currentDay, index) => {
			if (mode.id === "W" && index > 6) return null;
			const member = organisation?.members?.find((m) => m?.person === person?.person);
			const isActiveDay = currentDay?.formatted === new Intl.DateTimeFormat().format(activeDay?.date);
			const isCurrentMonth = parseInt(currentDay?.month) === parseInt(currentMonth);
			const modeStyle = mode.id === "W" ? "row-span-7" : "row-span-1";
			const activeDayStyle = isActiveDay ? "border-darkww-700 bg-sky-100" : "bg-white border-gray-500";
			const currentMonthStyle = isCurrentMonth || mode.id === "W" ? "opacity-100" : "opacity-50";
			let { shifts } = currentDay;
			let members = [];
			if (person?.isAdmin || person?.assignedAdmin || person?.assignedAdmin) members = organisation?.members;
			else if (member?.departmentIDs && member?.departmentIDs.length > 0)
				members = organisation.members.filter((k) => arrayDiff(k?.departmentIDs, member?.departmentIDs));
			else if (member?.departmentIDs?.length === 0 || member?.departmentIDs === null) members.push(member);
			if (filterByDepartment !== "ALL") members = members.filter((k) => k?.departmentIDs.includes(filterByDepartment));
			if (filterByRole !== "ALL" && (person?.isAdmin || person?.assignedAdmin)) {
				members = members.filter((k) => k?.roleIDs?.includes(filterByRole));
				shifts = shifts.filter((s) => s.roleID === filterByRole);
				let membersHavingFilteredShifts = [...new Set(shift.filter((s) => s.roleID === filterByRole).map((shift) => shift.memberID))];
				members = members.filter((k) => {
					return k?.roleIDs?.includes(filterByRole) || membersHavingFilteredShifts.includes(k.orgUserId);
				});
			}
			members.sort((a, b) => personUtils.displayName(a)?.localeCompare(personUtils.displayName(b)));
			shifts.sort((a, b) => personUtils.displayName(a.member)?.localeCompare(personUtils.displayName(b.member)));
			let timeOffData = getTimeOffData(timeOff, currentDay);
			const extraPayOfDay = extraPays.find((e) => e.baseDay === currentDay.baseEpoch);
			let totalStaffEarnings = calculateTotalEarnings(shifts, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, filterByDepartment);
			const preferences = preferenceData.filter((preference) => preference.preferenceDate === currentDay.baseEpoch);
			const punches = punchData.filter((k) => k.baseDay === currentDay.baseEpoch);
			let takings = currentDay?.report?.[0]?.target ?? currentDay?.average?.takings ?? 0;
			let percentage = organisation?.StaffCost ? takings * (organisation?.StaffCost / 100) - totalStaffEarnings : 0;

			return (
				<Fragment key={index}>
					{index === 0 && <EmployeeViewStaffList index={index} members={members} />}
					<Droppable
						droppableId={`${currentDay.baseEpoch}`}
						type="DAY"
						ignoreContainerClipping={Boolean(true)}
						isScrollable={true}
						isCombineEnabled={true}
						key={`day-${index}`}
					>
						{(provided, snapshot) => (
							<div
								key={`${currentDay.formatted}-${index}`}
								{...provided.droppableProps}
								ref={provided.innerRef}
								className={`${modeStyle} ${activeDayStyle} ${currentMonthStyle} col-span-1 cursor-pointer bg-gray-200 bg-opacity-40 hover:border-darkww-500 border-4 rounded-lg shadow-lg ${
									mode.id === "W"
										? ` ${zoom ? "min-w-[160px]" : "min-w-[183.4px]"}  w-full ${
												index + 1 === calendarDays.length ? `ml-1` : index === 0 ? `mr-1` : `mx-1`
										  }`
										: null
								} `}
							>
								<span
									type="button"
									onClick={(e) => clickOrDrag(snapshot, e, currentDay)}
									className={`{isDraggingOver ? "dragging-over" : ""} h-full w-full flex p-1`}
								>
									<div className="w-full flex flex-col">
										<div className="flex mb-1.5">
											<div>
												<div className="flex">
													<div className="text-gray-600 font-semibold">
														{currentDay.dayDesc?.slice(0, 2).toUpperCase() || ""}
													</div>
													<div className="text-darkww-600 font-semibold">{currentDay.day?.padStart(2, "0")}</div>
												</div>
												{(person?.isAdmin || person?.assignedAdmin) && !zoom && (
													<span>
														{extraPayOfDay && extraPayOfDay?.newPay > 1 && (
															<div className="text-pink-600 -mb-4 text-xs">+{extraPayOfDay?.newPay} %</div>
														)}
													</span>
												)}
											</div>
											{(person?.isAdmin || person?.assignedAdmin) && !zoom && (
												<div className="text-darkww-600 font-semibold">
													<ExtraPay
														currentDay={currentDay.baseEpoch}
														organisationID={organisation.id}
														record={extraPayOfDay}
														getExtraPay={getExtraPay}
													/>
												</div>
											)}
											<Preferred
												currentDay={currentDay}
												preferenceSave={preferenceSave}
												preferences={preferences}
												addNoteParticularDay={addNoteParticularDay}
												noteParticularDay={noteParticularDay}
												mode={mode}
												timeOffData={timeOffData}
												approveTimeOff={approveTimeOff}
											/>
										</div>

										<div className={`${zoom ? "mt-0.5" : "mt-0.5"} "mb-2 min-h-[60px]`}>
											{(person.isAdmin || person.assignedAdmin) && !zoom && (
												<div className={`${!shifts.length && "h-12 mt-1 pb-2 opacity-50 w-full text-center"}`}>
													<DayReport currentDay={currentDay} totalStaffEarnings={totalStaffEarnings} />
													{!currentDay?.report?.[0]?.takings && !shifts.length ? (
														<div className="mt-2 mb-1 text-lg">No Shifts Today</div>
													) : (
														<RateHourView
															holidayPay={holidayPay}
															percentage={percentage}
															earnings={totalStaffEarnings}
															currentDay={currentDay}
															timeOffData={timeOffData}
														/>
													)}
												</div>
											)}
											{mode.id === "W" && (
												<div className="mt-2">
													{members.map((p, im) => {
														const memberDayShifts = shifts.filter((k) => k.memberID === p.orgUserId);

														if (memberDayShifts.length > 1) {
															return (
																<MultipleShiftCard
																	key={`${p.orgUserId}--${currentDay.baseEpoch}`}
																	member={p}
																	currentDay={currentDay}
																	dayShifts={memberDayShifts}
																	memberIndex={im}
																	showChooseModelPopup={showChooseModelPopup}
																	pickNewDay={pickNewDay}
																	getDragStyle={getDragStyle}
																	preferences={preferences}
																	timeOffData={timeOffData}
																	punches={punches}
																/>
															);
														} else {
															const dayShift = shifts.find((k) => k.memberID === p.orgUserId);
															let isDisabled =
																dayShift === undefined
																	? true
																	: person.isAdmin || person.assignedAdmin
																	? false
																	: member.orgUserId !== dayShift?.memberID;
															return (
																<Fragment key={`${p.orgUserId}--${currentDay.baseEpoch}`}>
																	<Draggable
																		// key={shift ? shift.id : im + currentDay.baseEpoch}
																		draggableId={`${p?.orgUserId}--|--${
																			dayShift?.id ?? "grayed--|--" + currentDay.baseEpoch
																		}`}
																		index={im}
																		// offset={{ x: 0, y: -20 }}
																		isCombineEnabled={true}
																		isScrollable={false}
																		isDragDisabled={isDisabled}
																	>
																		{(provided, snapshot) => (
																			<>
																				{snapshot.isDragging ? <OnDragOutPlaceHolder zoom={zoom} /> : null}
																				<div
																					ref={provided.innerRef}
																					{...provided.draggableProps}
																					{...provided.dragHandleProps}
																					onClick={(e) =>
																						dayShift
																							? showChooseModelPopup(
																									currentDay,
																									dayShift,
																									e,
																									person.isAdmin || person.assignedAdmin
																										? "shift"
																										: "note"
																							  )
																							: pickNewDay(currentDay, "", e)
																					}
																					style={getDragStyle(
																						snapshot.isDragging,
																						provided.draggableProps.style,
																						zoom
																					)}
																					key={im}
																				>
																					<SmallShiftCardWithoutMemberName
																						shift={dayShift}
																						currentDay={currentDay}
																						pickNewDay={pickNewDay}
																						orgMembers={organisation.members || []}
																						preferences={preferences}
																						person={person}
																						timeOffData={timeOffData}
																						zoom={zoom}
																						punches={punches}
																					/>
																					{provided.placeholder}
																				</div>
																			</>
																		)}
																	</Draggable>
																</Fragment>
															);
														}
													})}
												</div>
											)}
										</div>
									</div>
								</span>
								<div style={{ display: "none" }}>{provided.placeholder}</div>
							</div>
						)}
					</Droppable>
				</Fragment>
			);
		});
	} else {
		// Start Time and Role VIEW
		return calendarDays.map((currentDay, index) => {
			if (mode.id === "W" && index > 6) return null;
			const isActiveDay = currentDay?.formatted === new Intl.DateTimeFormat().format(activeDay?.date);
			const isCurrentMonth = parseInt(currentDay?.month) === parseInt(currentMonth);
			const modeStyle = mode.id === "W" ? "row-span-6" : "row-span-1";
			const activeDayStyle = isActiveDay ? "border-darkww-700 bg-sky-100" : "bg-white border-gray-500";
			const currentMonthStyle = isCurrentMonth || mode.id === "W" ? "opacity-100" : "opacity-50";
			const { shifts } = currentDay;
			let sortedShifts = [...shifts]; // Create a new array from the existing shifts array

			if (!sortByRole) {
				sortedShifts.sort((a, b) => {
					if (a.shiftStart - b.shiftStart === 0) {
						return a?.role?.name?.localeCompare(b?.role?.name);
					}
					return a.shiftStart - b.shiftStart;
				});
			} else {
				sortedShifts.sort((a, b) => a?.role?.name?.localeCompare(b?.role?.name));
			}
			const preferences = preferenceData.filter((preference) => preference.preferenceDate === currentDay.baseEpoch);
			// const preferences = preferenceData.filter((preference) => {
			// 	if (preference.repeat) {
			// 		return currentDay.baseEpoch >= preference.preferenceDate && currentDay.baseEpoch <= preference.repeatEndDate;
			// 	}
			// 	return preference.preferenceDate === currentDay.baseEpoch;
			// });
			let timeOffData = getTimeOffData(timeOff, currentDay);
			const extraPayOfDay = extraPays.find((e) => e.baseDay === currentDay.baseEpoch);
			let totalStaffEarnings = holidayPay
				? 1.08 * calculateTotalEarnings(shifts, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, filterByDepartment)
				: calculateTotalEarnings(shifts, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, filterByDepartment);
			let takings = currentDay?.report?.[0]?.target ?? currentDay?.average?.takings ?? 0;
			let percentage = organisation?.StaffCost ? takings * (organisation?.StaffCost / 100) - totalStaffEarnings : 0;
			const punches = punchData.filter((k) => k.baseDay === currentDay.baseEpoch);

			return (
				<div
					key={`${currentDay.formatted} -${index} `}
					className={`${modeStyle} ${activeDayStyle} ${currentMonthStyle} col-span-1 cursor-pointer bg-gray-200 bg-opacity-40 hover:border-darkww-500 border-2 mb-3 rounded-lg shadow-lg ${
						mode.id === "W"
							? `min-w-[183.4px] w-full ${index + 1 === calendarDays.length ? `ml-2` : index === 0 ? `mr-2` : `mx-2`}`
							: null
					} `}
				>
					<span type="button" onClick={(e) => pickNewDay(currentDay, "", e)} className="h-full w-full flex p-1">
						<div className="w-full flex flex-col">
							<div className="flex">
								<div>
									<div className="flex">
										<div className="text-gray-600 font-semibold">{currentDay.dayDesc?.slice(0, 2).toUpperCase() || ""}</div>
										<div className="text-darkww-600 font-semibold">{currentDay.day?.padStart(2, "0")}</div>
									</div>
									{(person?.isAdmin || person?.assignedAdmin) && !zoom && (
										<span>
											{extraPayOfDay && extraPayOfDay?.newPay > 1 && (
												<div className="text-pink-600 -mb-4 text-xs">+{extraPayOfDay?.newPay} %</div>
											)}
										</span>
									)}
								</div>
								{(person?.isAdmin || person?.assignedAdmin) && !zoom && (
									<div className="text-darkww-600 font-semibold">
										<ExtraPay
											currentDay={currentDay.baseEpoch}
											organisationID={organisation.id}
											record={extraPayOfDay}
											getExtraPay={getExtraPay}
										/>
									</div>
								)}
								<Preferred
									currentDay={currentDay}
									preferenceSave={preferenceSave}
									preferences={preferences}
									addNoteParticularDay={addNoteParticularDay}
									noteParticularDay={noteParticularDay}
									mode={mode}
									timeOffData={timeOffData}
									approveTimeOff={approveTimeOff}
								/>
							</div>

							{shifts?.length > 0 ? (
								<div className="">
									{(person?.isAdmin || person?.assignedAdmin) && (
										<>
											{!currentDay?.report?.[0]?.takings && (
												<div className="flex flex-row mb-1 justify-center">
													<div className="mt-1 mr-1.5 text-2xl font-bold leading-6 text-gray-600 text-center">
														{shifts.length}
													</div>
													<div className="inline-block text-lg font-semibold text-purple-700 mt-1 text-center">Shifts</div>
												</div>
											)}
											<DayReport currentDay={currentDay} totalStaffEarnings={totalStaffEarnings} />
											<RateHourView
												holidayPay={holidayPay}
												percentage={percentage}
												earnings={totalStaffEarnings}
												currentDay={currentDay}
												timeOffData={timeOffData}
											/>
										</>
									)}
									{mode.id === "W" && (
										<div className="mt-3">
											{sortedShifts.map((shift, index) => {
												return (
													<div
														onClick={(e) =>
															showChooseModelPopup(
																currentDay,
																shift,
																e,
																person?.isAdmin || person?.assignedAdmin ? "shift" : "note"
															)
														}
														key={index}
													>
														<SmallShiftCard
															shift={shift}
															key={index}
															currentDay={currentDay}
															pickNewDay={pickNewDay}
															orgMembers={organisation.members.concat(organisation.deletedMembers) || []}
															preferences={preferences}
															person={person}
															timeOffData={timeOffData}
															punches={punches}
															removeShift={(e) => removeShift(e, currentDay, shift, filterByDepartment)}
														/>
													</div>
												);
											})}
										</div>
									)}
								</div>
							) : (
								<div className={`mt-1 opacity-60 w-full text-gray-400 ${mode.id === "W" && "h-screen"} text-center`}>
									<CalendarIcon className="h-10 w-10 mx-auto text-gray-300" aria-hidden="true" />
									{(person?.isAdmin || person?.assignedAdmin) && (
										<DayReport currentDay={currentDay} totalStaffEarnings={totalStaffEarnings} />
									)}
									<p>No shifts today</p>
								</div>
							)}
						</div>
					</span>
				</div>
			);
		});
	}
};

const DayReport = ({ currentDay, totalStaffEarnings }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const { shifts } = currentDay;
	const { rateOrHour, mode, view } = useContext(CalendarDataContext);
	const textColor = shifts?.length > 0 ? "purple" : "gray";

	const showPercentages = rateOrHour === "percentage" || (person?.assignedAdmin && person?.assignedFinancials === false);

	if (mode.id === "W" && view === "S") {
		// Employee view
		return (
			<>
				{!currentDay?.report?.[0]?.target && (
					<div className="text-sm text-purple-400 mx-auto flex justify-center">
						avg.{" "}
						{currentDay?.average?.takings
							? showPercentages
								? ((totalStaffEarnings / currentDay?.average?.takings) * 100).toFixed(1) + "%"
								: formateCurrency(currentDay?.average?.takings || 0)
							: 0}
						{currentDay?.average?.takings > currentDay?.report[0]?.takings ? (
							<TrendingDownIcon className="w-5 h-5 text-red-500" />
						) : (
							currentDay?.average?.takings < currentDay?.report[0]?.takings && <TrendingUpIcon className="w-5 h-5 text-green-500" />
						)}
					</div>
				)}
				<div>
					{currentDay?.report?.[0]?.target && (
						<>
							<div className="text-sm text-pink-500 mx-auto flex justify-center opacity-100">
								<TargetIcon className="h-4 w-4 mt-0.5 mb-0.5 mr-1 text-pink-500 opacity-100" /> {/* tar.{" "} */}
								{showPercentages
									? ((totalStaffEarnings / currentDay?.report?.[0]?.target) * 100).toFixed(1) + "%"
									: currentDay?.report?.[0]?.target}
							</div>
						</>
					)}
				</div>
			</>
		);
	}
	// Start Time and Role VIEW
	return (
		<div>
			<div className={`text-sm text-${textColor}-400 mx-auto flex justify-center`}>
				avg.{" "}
				{currentDay?.average?.takings
					? showPercentages
						? ((totalStaffEarnings / currentDay?.average?.takings) * 100).toFixed(1) + "%"
						: formateCurrency(currentDay?.average?.takings || 0)
					: 0}
				{currentDay?.average?.takings > currentDay?.report[0]?.takings ? (
					<TrendingDownIcon className="w-5 h-5 text-red-500" />
				) : (
					currentDay?.average?.takings < currentDay?.report[0]?.takings && <TrendingUpIcon className="w-5 h-5 text-green-500" />
				)}
			</div>
			<div>
				{currentDay?.report?.[0]?.target && (
					<>
						<div className="text-sm text-pink-500 mx-auto flex justify-center opacity-100">
							<TargetIcon className="h-4 w-4 my-0.5 mr-1 text-pink-500 opacity-100" /> {/* tar.{" "} */}
							{showPercentages
								? ((totalStaffEarnings / currentDay?.report?.[0]?.target) * 100).toFixed(1) + "%"
								: currentDay?.report?.[0]?.target}
						</div>
					</>
				)}
			</div>
			<div className={`text-sm text-${textColor}-400 flex justify-center`}>
				{currentDay?.report?.[0]?.takings && (
					<>
						<div className="text-sm text-purple-400 flex flex-col justify-center">
							{/* Displaying Take */}
							<div className="flex justify-center">
								Take:{" "}
								{showPercentages
									? ((totalStaffEarnings / currentDay?.report?.[0]?.takings) * 100).toFixed(1) + "%"
									: "€" + currentDay?.report?.[0]?.takings || 0}
							</div>

							{/* Displaying (-VAT) */}
							{organisation?.vat === true && (
								<div>
									(-VAT){" "}
									{showPercentages
										? (
												(totalStaffEarnings /
													(currentDay?.report?.[0]?.takings -
														currentDay?.report?.[0]?.food * (organisation.foodPercentage / 100) -
														currentDay?.report?.[0]?.drinks * (organisation.drinkPercentage / 100))) *
												100
										  ).toFixed(1) + "%"
										: "€" +
										  (
												currentDay?.report?.[0]?.takings -
												currentDay?.report?.[0]?.food * (organisation.foodPercentage / 100) -
												currentDay?.report?.[0]?.drinks * (organisation.drinkPercentage / 100)
										  ).toFixed(1)}
								</div>
							)}
						</div>

						{/* Displaying Trending Icons */}
						{currentDay?.report?.[0]?.takings < currentDay?.previousWeekReport?.[0]?.takings ? (
							<TrendingDownIcon className="w-5 h-5 text-red-500" />
						) : (
							currentDay?.report?.[0]?.takings > currentDay?.previousWeekReport?.[0]?.takings && (
								<TrendingUpIcon className="w-5 h-5 text-green-500" />
							)
						)}
					</>
				)}
			</div>

			<div className={`text-sm text-${textColor}-300 mx-auto flex justify-around`}>
				{currentDay?.report?.[0]?.food > 0 ? (
					<span className="flex text-purple-400">
						<FoodIcon className="h-5 w-5" fill="currentColor" />
						{showPercentages
							? (
									(totalStaffEarnings /
										(organisation?.vat === true && organisation.foodPercentage
											? currentDay?.report?.[0]?.food - currentDay?.report?.[0]?.food * (organisation.foodPercentage / 100)
											: currentDay?.report?.[0]?.food)) *
									100
							  ).toFixed(1) + "%"
							: "€" +
							  (organisation?.vat === true && organisation.foodPercentage
									? (currentDay?.report?.[0]?.food - currentDay?.report?.[0]?.food * (organisation.foodPercentage / 100)).toFixed(1)
									: currentDay?.report?.[0]?.food)}
						{currentDay?.report?.[0]?.food < currentDay?.previousWeekReport?.[0]?.food ? (
							<TrendingDownIcon className="w-3 h-3 text-red-500" />
						) : (
							currentDay?.report?.[0]?.food > currentDay?.previousWeekReport?.[0]?.food && (
								<TrendingUpIcon className="w-3 h-3 text-green-500" />
							)
						)}
					</span>
				) : (
					""
				)}
				{currentDay?.report?.[0]?.drinks > 0 ? (
					<span className="flex text-purple-400">
						<MartiniIcon fill="currentColor" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
						{showPercentages
							? (
									(totalStaffEarnings /
										(organisation?.vat === true && organisation.drinkPercentage
											? currentDay?.report?.[0]?.drinks - currentDay?.report?.[0]?.drinks * (organisation.drinkPercentage / 100)
											: currentDay?.report?.[0]?.drinks)) *
									100
							  ).toFixed(1) + "%"
							: "€" +
							  (organisation?.vat === true && organisation.drinkPercentage
									? (
											currentDay?.report?.[0]?.drinks -
											currentDay?.report?.[0]?.drinks * (organisation.drinkPercentage / 100)
									  ).toFixed(1)
									: currentDay?.report?.[0]?.drinks)}
						{currentDay?.report?.[0]?.drinks < currentDay?.previousWeekReport?.[0]?.drinks ? (
							<TrendingDownIcon className="w-3 h-3 text-red-500" />
						) : (
							currentDay?.report?.[0]?.drinks > currentDay?.previousWeekReport?.[0]?.drinks && (
								<TrendingUpIcon className="w-3 h-3 text-green-500" />
							)
						)}
					</span>
				) : (
					""
				)}
			</div>
		</div>
	);
};

const LiveCostView = ({ holidayPay, percentage, currentDay, timeOffData, showLiveCost, setShowLiveCost }) => {
	const { rateOrHour, setRateOrHour, extraPays, paidTimeOff, filterByDepartment, punchData } = useContext(CalendarDataContext);
	const { shifts } = currentDay;

	const extraPayOfDay = extraPays.find((e) => e.baseDay === currentDay.baseEpoch);
	const punches = punchData.filter((k) => k.baseDay === currentDay.baseEpoch);

	let liveRosteredCost = holidayPay
		? 1.08 * calculateLiveRosteredCost(shifts, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, filterByDepartment)
		: calculateLiveRosteredCost(shifts, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, filterByDepartment);

	let actualStaffEarnings = holidayPay
		? 1.08 * calculateLiveActualEarnings(shifts, punches, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, filterByDepartment)
		: calculateLiveActualEarnings(shifts, punches, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, filterByDepartment);

	const switchRateAndHour = (e) => {
		if (e !== "") e.stopPropagation();
		if (rateOrHour === "hour") setRateOrHour("rate");
		else if (rateOrHour === "rate") setRateOrHour("percentage");
		else if (rateOrHour === "percentage") setRateOrHour("hour");
	};

	let actualStaffCostForDay = actualStaffEarnings;
	let borderAndTextClass = actualStaffCostForDay <= liveRosteredCost ? "border-pink-600 text-pink-600" : "border-lightww-500 text-lightww-500";

	return (
		<div className="flex justify-between">
			<div
				className={`bg-pink-600 mt-1 border-2 border-white shadow-sm rounded-md px-2 w-fit mx-auto courser`}
				onClick={(e) => switchRateAndHour(e)}
			>
				<div className="text-white text-md flex">
					<span>€{liveRosteredCost.toFixed(1)}</span>
				</div>
			</div>
			<div
				className={`bg-white mt-1 border-2 border-pink-600 ${borderAndTextClass} shadow-sm rounded-md px-1 w-fit mx-auto courser`}
				onClick={(e) => {
					if (e !== "") e.stopPropagation();
					return setShowLiveCost(!showLiveCost);
				}}
			>
				<div className="text-md text-pink-600 flex">
					<span>€{actualStaffCostForDay.toFixed(1)}</span>
				</div>
			</div>
		</div>
	);
};

const RateHourView = ({ holidayPay, percentage, earnings, currentDay, timeOffData }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const { rateOrHour, setRateOrHour, extraPays, paidTimeOff, filterByDepartment, punchData } = useContext(CalendarDataContext);
	const { shifts } = currentDay;

	const extraPayOfDay = extraPays.find((e) => e.baseDay === currentDay.baseEpoch);
	const clockInEnabled = organisation?.disableClockIn === "NO";
	const punches = !clockInEnabled ? [] : punchData.filter((k) => k.baseDay === currentDay.baseEpoch);

	const today = new Date().setHours(0, 0, 0, 0);
	const currentDate = new Date(currentDay.date).setHours(0, 0, 0, 0);
	const currentDateIsTodayOrYest = today === currentDate || today - currentDate === 86400000;
	const [showLiveCost, setShowLiveCost] = useState(false);

	let bgClass = rateOrHour === "percentage" && percentage > 0 ? "bg-green-600" : "bg-pink-600";
	let overStaffed = rateOrHour === "percentage" && percentage > 0 ? "below%" : "above%";

	const switchRateAndHour = (e) => {
		if (e !== "") e.stopPropagation();
		if (rateOrHour === "hour") {
			if (person?.assignedAdmin && person?.assignedFinancials === false) setRateOrHour("percentage");
			else setRateOrHour("rate");
		} else if (rateOrHour === "rate") setRateOrHour("percentage");
		else if (rateOrHour === "percentage") setRateOrHour("hour");
	};

	let actualStaffCostForDay = 0;
	if (clockInEnabled) {
		actualStaffCostForDay = holidayPay
			? 1.08 * calculateLiveActualEarnings(shifts, punches, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, filterByDepartment)
			: calculateLiveActualEarnings(shifts, punches, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, filterByDepartment);
	} else {
		actualStaffCostForDay = holidayPay
			? 1.08 * calculateLiveRosteredCost(shifts, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, filterByDepartment)
			: calculateLiveRosteredCost(shifts, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, filterByDepartment);
	}

	if (showLiveCost) {
		return (
			<LiveCostView
				holidayPay={holidayPay}
				percentage={percentage}
				earnings={earnings}
				currentDay={currentDay}
				timeOffData={timeOffData}
				showLiveCost={showLiveCost}
				setShowLiveCost={setShowLiveCost}
			/>
		);
	} else {
		return (
			<div className="flex justify-between items-center">
				{(person?.isAdmin || person?.assignedAdmin) && (
					<div
						className={`${bgClass} mt-1 border-2 border-white shadow-sm rounded-md px-2 w-fit mx-auto courser`}
						onClick={(e) => switchRateAndHour(e)}
					>
						{rateOrHour === "hour" && (
							<div className="text-white text-md">
								{holidayPay
									? (1.08 * calculateTotalTime(shifts)).toFixed(1).replace(/[.,]00$/, "")
									: calculateTotalTime(shifts)
											.toFixed(1)
											.replace(/[.,]00$/, "")}
								<span className="text-sm pl-1 text-whiteww-900">hrs</span>
							</div>
						)}
						{rateOrHour === "rate" && (
							<div className="text-white text-md flex">
								<span>€{earnings.toFixed(1)}</span>
							</div>
						)}
						{rateOrHour === "percentage" && organisation?.StaffCost && (
							<div className="text-white text-md flex">
								<span className="mr-1">€{Math.round(percentage * 10) / 10}</span>{" "}
								<span className="text-xs flex items-center"> ({overStaffed})</span>
							</div>
						)}
					</div>
				)}
				{clockInEnabled && rateOrHour === "rate" && currentDateIsTodayOrYest && (
					<div
						className={`bg-green-600 mt-1 border-2 border-white text-white text-sm shadow-sm rounded-md px-2 w-fit mx-auto cursor h-full flex justify-center items-center`}
						onClick={(e) => {
							if (e !== "") e.stopPropagation();
							return setShowLiveCost(!showLiveCost);
						}}
					>
						<RefreshIcon className="h-4 w-4 mr-1" />
						<span>Live</span>
					</div>
				)}
				{rateOrHour === "rate" &&
					(!currentDateIsTodayOrYest || (currentDateIsTodayOrYest && !clockInEnabled)) &&
					(() => {
						const borderAndTextClass =
							actualStaffCostForDay <= earnings ? "border-pink-600 text-pink-600" : "border-lightww-500 text-lightww-500";

						return (
							<div className={`bg-white mt-1 border-2 ${borderAndTextClass} shadow-sm rounded-md px-1 w-fit mx-auto courser`}>
								<div className="text-md flex">
									<span>€{actualStaffCostForDay.toFixed(1)}</span>
								</div>
							</div>
						);
					})()}
			</div>
		);
	}
};

export const OnDragOutPlaceHolder = ({ zoom }) => {
	return (
		<div className={`${zoom ? "h-9 pt-9" : "h-18 pt-18"} flex items-center`} style={{ opacity: 0.5 }}>
			<div className="flex flex-row flex-1 bg-gray-100 py-0 shadow-md rounded-md">
				<div className="h-16 flex-1 items-center">
					<div className="pl-2 text-left"></div>
				</div>
			</div>
		</div>
	);
};

export default DayCards;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      person
      firstName
      lastName
      username
      salary
      employeePhone
      emergencyNo
      avatarURI
      pin
      organisations {
        items {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      person
      firstName
      lastName
      username
      salary
      employeePhone
      emergencyNo
      avatarURI
      pin
      organisations {
        items {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      person
      firstName
      lastName
      username
      salary
      employeePhone
      emergencyNo
      avatarURI
      pin
      organisations {
        items {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation(
    $input: CreateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    createOrganisation(input: $input, condition: $condition) {
      id
      name
      address
      phone
      StaffCost
      logoURI
      hrEmail
      vat
      paidBreak
      allPaidBreak
      shiftCompliance
      enablePhoto
      disableClockIn
      startDay
      lateClockOutNotificationHours
      foodPercentage
      drinkPercentage
      staffInputhourlyRate
      staffClockInView
      staffHolidayPay
      members {
        items {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      roles {
        items {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      preferences {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          organisationID
          preferenceDate
          repeat
          repeatEndDate
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      noteParticularDay {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          note
          organisationID
          noteDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      timeOff {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          organisationID
          fromDate
          toDate
          repeatDay
          status
          createdAt
          isPaid
          note
          updatedAt
          owner
        }
        nextToken
      }
      request {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fromShiftId
          fromShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          toShiftId
          toShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      template {
        items {
          id
          name
          memberID
          organisationID
          shifts
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      report {
        items {
          id
          takings
          target
          till
          food
          drinks
          note
          organisationID
          reportDate
          reportDay
          yearMonth
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      giveAway {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shiftId
          shift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      message {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toMemberID
          filterChat
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readStatus
          updatedAt
          owner
        }
        nextToken
      }
      roleMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toRoleID
          toRole {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      departments {
        items {
          id
          organisationID
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscription {
        items {
          id
          organisationID
          refrenceId
          quantity
          SubscriptionStatus
          cancelAtPeriodEnd
          expiryAt
          createdAt
          updatedAt
          lastQuantityUpdatedAt
          owner
        }
        nextToken
      }
      deptMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toDeptID
          toDept {
            id
            organisationID
            name
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      orgAllMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      checkIns {
        items {
          id
          organisationID
          name
          duration
          unit
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      enableStaffTimeOff
      enableStaffPreferences
      earlyClockInNotificationHours
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOrganisation = /* GraphQL */ `
  mutation UpdateOrganisation(
    $input: UpdateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    updateOrganisation(input: $input, condition: $condition) {
      id
      name
      address
      phone
      StaffCost
      logoURI
      hrEmail
      vat
      paidBreak
      allPaidBreak
      shiftCompliance
      enablePhoto
      disableClockIn
      startDay
      lateClockOutNotificationHours
      foodPercentage
      drinkPercentage
      staffInputhourlyRate
      staffClockInView
      staffHolidayPay
      members {
        items {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      roles {
        items {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      preferences {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          organisationID
          preferenceDate
          repeat
          repeatEndDate
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      noteParticularDay {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          note
          organisationID
          noteDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      timeOff {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          organisationID
          fromDate
          toDate
          repeatDay
          status
          createdAt
          isPaid
          note
          updatedAt
          owner
        }
        nextToken
      }
      request {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fromShiftId
          fromShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          toShiftId
          toShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      template {
        items {
          id
          name
          memberID
          organisationID
          shifts
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      report {
        items {
          id
          takings
          target
          till
          food
          drinks
          note
          organisationID
          reportDate
          reportDay
          yearMonth
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      giveAway {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shiftId
          shift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      message {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toMemberID
          filterChat
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readStatus
          updatedAt
          owner
        }
        nextToken
      }
      roleMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toRoleID
          toRole {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      departments {
        items {
          id
          organisationID
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscription {
        items {
          id
          organisationID
          refrenceId
          quantity
          SubscriptionStatus
          cancelAtPeriodEnd
          expiryAt
          createdAt
          updatedAt
          lastQuantityUpdatedAt
          owner
        }
        nextToken
      }
      deptMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toDeptID
          toDept {
            id
            organisationID
            name
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      orgAllMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      checkIns {
        items {
          id
          organisationID
          name
          duration
          unit
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      enableStaffTimeOff
      enableStaffPreferences
      earlyClockInNotificationHours
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOrganisation = /* GraphQL */ `
  mutation DeleteOrganisation(
    $input: DeleteOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    deleteOrganisation(input: $input, condition: $condition) {
      id
      name
      address
      phone
      StaffCost
      logoURI
      hrEmail
      vat
      paidBreak
      allPaidBreak
      shiftCompliance
      enablePhoto
      disableClockIn
      startDay
      lateClockOutNotificationHours
      foodPercentage
      drinkPercentage
      staffInputhourlyRate
      staffClockInView
      staffHolidayPay
      members {
        items {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      roles {
        items {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      preferences {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          organisationID
          preferenceDate
          repeat
          repeatEndDate
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      noteParticularDay {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          note
          organisationID
          noteDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      timeOff {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          organisationID
          fromDate
          toDate
          repeatDay
          status
          createdAt
          isPaid
          note
          updatedAt
          owner
        }
        nextToken
      }
      request {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fromShiftId
          fromShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          toShiftId
          toShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      template {
        items {
          id
          name
          memberID
          organisationID
          shifts
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      report {
        items {
          id
          takings
          target
          till
          food
          drinks
          note
          organisationID
          reportDate
          reportDay
          yearMonth
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      giveAway {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shiftId
          shift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      message {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toMemberID
          filterChat
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readStatus
          updatedAt
          owner
        }
        nextToken
      }
      roleMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toRoleID
          toRole {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      departments {
        items {
          id
          organisationID
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscription {
        items {
          id
          organisationID
          refrenceId
          quantity
          SubscriptionStatus
          cancelAtPeriodEnd
          expiryAt
          createdAt
          updatedAt
          lastQuantityUpdatedAt
          owner
        }
        nextToken
      }
      deptMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toDeptID
          toDept {
            id
            organisationID
            name
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      orgAllMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      checkIns {
        items {
          id
          organisationID
          name
          duration
          unit
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      enableStaffTimeOff
      enableStaffPreferences
      earlyClockInNotificationHours
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createStripeSubscription = /* GraphQL */ `
  mutation CreateStripeSubscription(
    $input: CreateStripeSubscriptionInput!
    $condition: ModelStripeSubscriptionConditionInput
  ) {
    createStripeSubscription(input: $input, condition: $condition) {
      id
      organisationID
      refrenceId
      quantity
      SubscriptionStatus
      cancelAtPeriodEnd
      expiryAt
      createdAt
      updatedAt
      lastQuantityUpdatedAt
      owner
    }
  }
`;
export const updateStripeSubscription = /* GraphQL */ `
  mutation UpdateStripeSubscription(
    $input: UpdateStripeSubscriptionInput!
    $condition: ModelStripeSubscriptionConditionInput
  ) {
    updateStripeSubscription(input: $input, condition: $condition) {
      id
      organisationID
      refrenceId
      quantity
      SubscriptionStatus
      cancelAtPeriodEnd
      expiryAt
      createdAt
      updatedAt
      lastQuantityUpdatedAt
      owner
    }
  }
`;
export const deleteStripeSubscription = /* GraphQL */ `
  mutation DeleteStripeSubscription(
    $input: DeleteStripeSubscriptionInput!
    $condition: ModelStripeSubscriptionConditionInput
  ) {
    deleteStripeSubscription(input: $input, condition: $condition) {
      id
      organisationID
      refrenceId
      quantity
      SubscriptionStatus
      cancelAtPeriodEnd
      expiryAt
      createdAt
      updatedAt
      lastQuantityUpdatedAt
      owner
    }
  }
`;
export const createOrganisationMember = /* GraphQL */ `
  mutation CreateOrganisationMember(
    $input: CreateOrganisationMemberInput!
    $condition: ModelOrganisationMemberConditionInput
  ) {
    createOrganisationMember(input: $input, condition: $condition) {
      id
      organisationID
      personID
      orgUsername
      email
      roleID
      departmentID
      status
      employeeFile
      huorlyRate
      maxHours
      employeeNu
      empRateUnit
      organisation {
        id
        name
        address
        phone
        StaffCost
        logoURI
        hrEmail
        vat
        paidBreak
        allPaidBreak
        shiftCompliance
        enablePhoto
        disableClockIn
        startDay
        lateClockOutNotificationHours
        foodPercentage
        drinkPercentage
        staffInputhourlyRate
        staffClockInView
        staffHolidayPay
        members {
          items {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        roles {
          items {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        timeOff {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            organisationID
            fromDate
            toDate
            repeatDay
            status
            createdAt
            isPaid
            note
            updatedAt
            owner
          }
          nextToken
        }
        request {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        template {
          items {
            id
            name
            memberID
            organisationID
            shifts
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        report {
          items {
            id
            takings
            target
            till
            food
            drinks
            note
            organisationID
            reportDate
            reportDay
            yearMonth
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        giveAway {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            shiftId
            shift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        message {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toMemberID
            filterChat
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            readStatus
            updatedAt
            owner
          }
          nextToken
        }
        roleMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toRoleID
            toRole {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        departments {
          items {
            id
            organisationID
            name
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscription {
          items {
            id
            organisationID
            refrenceId
            quantity
            SubscriptionStatus
            cancelAtPeriodEnd
            expiryAt
            createdAt
            updatedAt
            lastQuantityUpdatedAt
            owner
          }
          nextToken
        }
        deptMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toDeptID
            toDept {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        orgAllMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        checkIns {
          items {
            id
            organisationID
            name
            duration
            unit
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        enableStaffTimeOff
        enableStaffPreferences
        earlyClockInNotificationHours
        createdAt
        updatedAt
        owner
      }
      person {
        id
        person
        firstName
        lastName
        username
        salary
        employeePhone
        emergencyNo
        avatarURI
        pin
        organisations {
          items {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      invitations {
        items {
          id
          orgMemberID
          email
          status
          orgMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      preferences {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          organisationID
          preferenceDate
          repeat
          repeatEndDate
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      noteParticularDay {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          note
          organisationID
          noteDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      fromRequest {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fromShiftId
          fromShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          toShiftId
          toShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      assignedAdmin
      assignedFinancials
      assignedRequests
      assignedSuperAdmin
      isPayRoll
      disciplinary
      employeeNotes
      startDate
      completedCheckIns {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          checkInID
          organisationID
          note
          date
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOrganisationMember = /* GraphQL */ `
  mutation UpdateOrganisationMember(
    $input: UpdateOrganisationMemberInput!
    $condition: ModelOrganisationMemberConditionInput
  ) {
    updateOrganisationMember(input: $input, condition: $condition) {
      id
      organisationID
      personID
      orgUsername
      email
      roleID
      departmentID
      status
      employeeFile
      huorlyRate
      maxHours
      employeeNu
      empRateUnit
      organisation {
        id
        name
        address
        phone
        StaffCost
        logoURI
        hrEmail
        vat
        paidBreak
        allPaidBreak
        shiftCompliance
        enablePhoto
        disableClockIn
        startDay
        lateClockOutNotificationHours
        foodPercentage
        drinkPercentage
        staffInputhourlyRate
        staffClockInView
        staffHolidayPay
        members {
          items {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        roles {
          items {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        timeOff {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            organisationID
            fromDate
            toDate
            repeatDay
            status
            createdAt
            isPaid
            note
            updatedAt
            owner
          }
          nextToken
        }
        request {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        template {
          items {
            id
            name
            memberID
            organisationID
            shifts
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        report {
          items {
            id
            takings
            target
            till
            food
            drinks
            note
            organisationID
            reportDate
            reportDay
            yearMonth
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        giveAway {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            shiftId
            shift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        message {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toMemberID
            filterChat
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            readStatus
            updatedAt
            owner
          }
          nextToken
        }
        roleMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toRoleID
            toRole {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        departments {
          items {
            id
            organisationID
            name
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscription {
          items {
            id
            organisationID
            refrenceId
            quantity
            SubscriptionStatus
            cancelAtPeriodEnd
            expiryAt
            createdAt
            updatedAt
            lastQuantityUpdatedAt
            owner
          }
          nextToken
        }
        deptMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toDeptID
            toDept {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        orgAllMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        checkIns {
          items {
            id
            organisationID
            name
            duration
            unit
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        enableStaffTimeOff
        enableStaffPreferences
        earlyClockInNotificationHours
        createdAt
        updatedAt
        owner
      }
      person {
        id
        person
        firstName
        lastName
        username
        salary
        employeePhone
        emergencyNo
        avatarURI
        pin
        organisations {
          items {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      invitations {
        items {
          id
          orgMemberID
          email
          status
          orgMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      preferences {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          organisationID
          preferenceDate
          repeat
          repeatEndDate
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      noteParticularDay {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          note
          organisationID
          noteDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      fromRequest {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fromShiftId
          fromShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          toShiftId
          toShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      assignedAdmin
      assignedFinancials
      assignedRequests
      assignedSuperAdmin
      isPayRoll
      disciplinary
      employeeNotes
      startDate
      completedCheckIns {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          checkInID
          organisationID
          note
          date
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOrganisationMember = /* GraphQL */ `
  mutation DeleteOrganisationMember(
    $input: DeleteOrganisationMemberInput!
    $condition: ModelOrganisationMemberConditionInput
  ) {
    deleteOrganisationMember(input: $input, condition: $condition) {
      id
      organisationID
      personID
      orgUsername
      email
      roleID
      departmentID
      status
      employeeFile
      huorlyRate
      maxHours
      employeeNu
      empRateUnit
      organisation {
        id
        name
        address
        phone
        StaffCost
        logoURI
        hrEmail
        vat
        paidBreak
        allPaidBreak
        shiftCompliance
        enablePhoto
        disableClockIn
        startDay
        lateClockOutNotificationHours
        foodPercentage
        drinkPercentage
        staffInputhourlyRate
        staffClockInView
        staffHolidayPay
        members {
          items {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        roles {
          items {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        timeOff {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            organisationID
            fromDate
            toDate
            repeatDay
            status
            createdAt
            isPaid
            note
            updatedAt
            owner
          }
          nextToken
        }
        request {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        template {
          items {
            id
            name
            memberID
            organisationID
            shifts
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        report {
          items {
            id
            takings
            target
            till
            food
            drinks
            note
            organisationID
            reportDate
            reportDay
            yearMonth
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        giveAway {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            shiftId
            shift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        message {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toMemberID
            filterChat
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            readStatus
            updatedAt
            owner
          }
          nextToken
        }
        roleMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toRoleID
            toRole {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        departments {
          items {
            id
            organisationID
            name
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscription {
          items {
            id
            organisationID
            refrenceId
            quantity
            SubscriptionStatus
            cancelAtPeriodEnd
            expiryAt
            createdAt
            updatedAt
            lastQuantityUpdatedAt
            owner
          }
          nextToken
        }
        deptMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toDeptID
            toDept {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        orgAllMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        checkIns {
          items {
            id
            organisationID
            name
            duration
            unit
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        enableStaffTimeOff
        enableStaffPreferences
        earlyClockInNotificationHours
        createdAt
        updatedAt
        owner
      }
      person {
        id
        person
        firstName
        lastName
        username
        salary
        employeePhone
        emergencyNo
        avatarURI
        pin
        organisations {
          items {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      invitations {
        items {
          id
          orgMemberID
          email
          status
          orgMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      preferences {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          organisationID
          preferenceDate
          repeat
          repeatEndDate
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      noteParticularDay {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          note
          organisationID
          noteDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      fromRequest {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fromShiftId
          fromShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          toShiftId
          toShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      assignedAdmin
      assignedFinancials
      assignedRequests
      assignedSuperAdmin
      isPayRoll
      disciplinary
      employeeNotes
      startDate
      completedCheckIns {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          checkInID
          organisationID
          note
          date
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      orgMemberID
      email
      status
      orgMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      orgMemberID
      email
      status
      orgMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      orgMemberID
      email
      status
      orgMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      organisationID
      name
      labelColour
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      organisationID
      name
      labelColour
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      organisationID
      name
      labelColour
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createShift = /* GraphQL */ `
  mutation CreateShift(
    $input: CreateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    createShift(input: $input, condition: $condition) {
      id
      organisationID
      baseDay
      shiftStart
      shiftFilter
      shiftEnd
      roleID
      role {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      hasBreak
      breakStart
      breakEnd
      weekStartDay
      breakDuration
      notes {
        items {
          id
          shiftID
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shiftStatus
      isShiftPublished
      unPaidBreak
      clockIn
      clockOut
      clockInBreak
      newPay
      setAsClose
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateShift = /* GraphQL */ `
  mutation UpdateShift(
    $input: UpdateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    updateShift(input: $input, condition: $condition) {
      id
      organisationID
      baseDay
      shiftStart
      shiftFilter
      shiftEnd
      roleID
      role {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      hasBreak
      breakStart
      breakEnd
      weekStartDay
      breakDuration
      notes {
        items {
          id
          shiftID
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shiftStatus
      isShiftPublished
      unPaidBreak
      clockIn
      clockOut
      clockInBreak
      newPay
      setAsClose
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteShift = /* GraphQL */ `
  mutation DeleteShift(
    $input: DeleteShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    deleteShift(input: $input, condition: $condition) {
      id
      organisationID
      baseDay
      shiftStart
      shiftFilter
      shiftEnd
      roleID
      role {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      hasBreak
      breakStart
      breakEnd
      weekStartDay
      breakDuration
      notes {
        items {
          id
          shiftID
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shiftStatus
      isShiftPublished
      unPaidBreak
      clockIn
      clockOut
      clockInBreak
      newPay
      setAsClose
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createNotes = /* GraphQL */ `
  mutation CreateNotes(
    $input: CreateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    createNotes(input: $input, condition: $condition) {
      id
      shiftID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNotes = /* GraphQL */ `
  mutation UpdateNotes(
    $input: UpdateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    updateNotes(input: $input, condition: $condition) {
      id
      shiftID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNotes = /* GraphQL */ `
  mutation DeleteNotes(
    $input: DeleteNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    deleteNotes(input: $input, condition: $condition) {
      id
      shiftID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPreference = /* GraphQL */ `
  mutation CreatePreference(
    $input: CreatePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    createPreference(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      status
      organisationID
      preferenceDate
      repeat
      repeatEndDate
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePreference = /* GraphQL */ `
  mutation UpdatePreference(
    $input: UpdatePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    updatePreference(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      status
      organisationID
      preferenceDate
      repeat
      repeatEndDate
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePreference = /* GraphQL */ `
  mutation DeletePreference(
    $input: DeletePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    deletePreference(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      status
      organisationID
      preferenceDate
      repeat
      repeatEndDate
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createNoteParticularDay = /* GraphQL */ `
  mutation CreateNoteParticularDay(
    $input: CreateNoteParticularDayInput!
    $condition: ModelNoteParticularDayConditionInput
  ) {
    createNoteParticularDay(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      note
      organisationID
      noteDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNoteParticularDay = /* GraphQL */ `
  mutation UpdateNoteParticularDay(
    $input: UpdateNoteParticularDayInput!
    $condition: ModelNoteParticularDayConditionInput
  ) {
    updateNoteParticularDay(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      note
      organisationID
      noteDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNoteParticularDay = /* GraphQL */ `
  mutation DeleteNoteParticularDay(
    $input: DeleteNoteParticularDayInput!
    $condition: ModelNoteParticularDayConditionInput
  ) {
    deleteNoteParticularDay(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      note
      organisationID
      noteDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      name
      memberID
      organisationID
      shifts
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      name
      memberID
      organisationID
      shifts
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      name
      memberID
      organisationID
      shifts
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment(
    $input: CreateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    createDepartment(input: $input, condition: $condition) {
      id
      organisationID
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment(
    $input: UpdateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    updateDepartment(input: $input, condition: $condition) {
      id
      organisationID
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment(
    $input: DeleteDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    deleteDepartment(input: $input, condition: $condition) {
      id
      organisationID
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTimeOff = /* GraphQL */ `
  mutation CreateTimeOff(
    $input: CreateTimeOffInput!
    $condition: ModelTimeOffConditionInput
  ) {
    createTimeOff(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      organisationID
      fromDate
      toDate
      repeatDay
      status
      createdAt
      isPaid
      note
      updatedAt
      owner
    }
  }
`;
export const updateTimeOff = /* GraphQL */ `
  mutation UpdateTimeOff(
    $input: UpdateTimeOffInput!
    $condition: ModelTimeOffConditionInput
  ) {
    updateTimeOff(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      organisationID
      fromDate
      toDate
      repeatDay
      status
      createdAt
      isPaid
      note
      updatedAt
      owner
    }
  }
`;
export const deleteTimeOff = /* GraphQL */ `
  mutation DeleteTimeOff(
    $input: DeleteTimeOffInput!
    $condition: ModelTimeOffConditionInput
  ) {
    deleteTimeOff(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      organisationID
      fromDate
      toDate
      repeatDay
      status
      createdAt
      isPaid
      note
      updatedAt
      owner
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      memberID
      toMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fromShiftId
      fromShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      toShiftId
      toShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      expirationTime
      note
      updatedAt
      owner
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      memberID
      toMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fromShiftId
      fromShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      toShiftId
      toShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      expirationTime
      note
      updatedAt
      owner
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      memberID
      toMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fromShiftId
      fromShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      toShiftId
      toShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      expirationTime
      note
      updatedAt
      owner
    }
  }
`;
export const createAllMemberSwapShift = /* GraphQL */ `
  mutation CreateAllMemberSwapShift(
    $input: CreateAllMemberSwapShiftInput!
    $condition: ModelAllMemberSwapShiftConditionInput
  ) {
    createAllMemberSwapShift(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fromShiftId
      fromShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      roleID
      expirationTime
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAllMemberSwapShift = /* GraphQL */ `
  mutation UpdateAllMemberSwapShift(
    $input: UpdateAllMemberSwapShiftInput!
    $condition: ModelAllMemberSwapShiftConditionInput
  ) {
    updateAllMemberSwapShift(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fromShiftId
      fromShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      roleID
      expirationTime
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAllMemberSwapShift = /* GraphQL */ `
  mutation DeleteAllMemberSwapShift(
    $input: DeleteAllMemberSwapShiftInput!
    $condition: ModelAllMemberSwapShiftConditionInput
  ) {
    deleteAllMemberSwapShift(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fromShiftId
      fromShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      roleID
      expirationTime
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      takings
      target
      till
      food
      drinks
      note
      organisationID
      reportDate
      reportDay
      yearMonth
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      takings
      target
      till
      food
      drinks
      note
      organisationID
      reportDate
      reportDay
      yearMonth
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      takings
      target
      till
      food
      drinks
      note
      organisationID
      reportDate
      reportDay
      yearMonth
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGiveAway = /* GraphQL */ `
  mutation CreateGiveAway(
    $input: CreateGiveAwayInput!
    $condition: ModelGiveAwayConditionInput
  ) {
    createGiveAway(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shiftId
      shift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      expirationTime
      note
      updatedAt
      owner
    }
  }
`;
export const updateGiveAway = /* GraphQL */ `
  mutation UpdateGiveAway(
    $input: UpdateGiveAwayInput!
    $condition: ModelGiveAwayConditionInput
  ) {
    updateGiveAway(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shiftId
      shift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      expirationTime
      note
      updatedAt
      owner
    }
  }
`;
export const deleteGiveAway = /* GraphQL */ `
  mutation DeleteGiveAway(
    $input: DeleteGiveAwayInput!
    $condition: ModelGiveAwayConditionInput
  ) {
    deleteGiveAway(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shiftId
      shift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      expirationTime
      note
      updatedAt
      owner
    }
  }
`;
export const createAllMemberGiveAway = /* GraphQL */ `
  mutation CreateAllMemberGiveAway(
    $input: CreateAllMemberGiveAwayInput!
    $condition: ModelAllMemberGiveAwayConditionInput
  ) {
    createAllMemberGiveAway(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shiftId
      shift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      roleID
      expirationTime
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAllMemberGiveAway = /* GraphQL */ `
  mutation UpdateAllMemberGiveAway(
    $input: UpdateAllMemberGiveAwayInput!
    $condition: ModelAllMemberGiveAwayConditionInput
  ) {
    updateAllMemberGiveAway(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shiftId
      shift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      roleID
      expirationTime
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAllMemberGiveAway = /* GraphQL */ `
  mutation DeleteAllMemberGiveAway(
    $input: DeleteAllMemberGiveAwayInput!
    $condition: ModelAllMemberGiveAwayConditionInput
  ) {
    deleteAllMemberGiveAway(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shiftId
      shift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          maxHours
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            shiftCompliance
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            earlyClockInNotificationHours
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedFinancials
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      roleID
      expirationTime
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toMemberID
      filterChat
      toMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readStatus
      updatedAt
      owner
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toMemberID
      filterChat
      toMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readStatus
      updatedAt
      owner
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toMemberID
      filterChat
      toMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readStatus
      updatedAt
      owner
    }
  }
`;
export const createRoleMessage = /* GraphQL */ `
  mutation CreateRoleMessage(
    $input: CreateRoleMessageInput!
    $condition: ModelRoleMessageConditionInput
  ) {
    createRoleMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toRoleID
      toRole {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const updateRoleMessage = /* GraphQL */ `
  mutation UpdateRoleMessage(
    $input: UpdateRoleMessageInput!
    $condition: ModelRoleMessageConditionInput
  ) {
    updateRoleMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toRoleID
      toRole {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const deleteRoleMessage = /* GraphQL */ `
  mutation DeleteRoleMessage(
    $input: DeleteRoleMessageInput!
    $condition: ModelRoleMessageConditionInput
  ) {
    deleteRoleMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toRoleID
      toRole {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const createDeptMessage = /* GraphQL */ `
  mutation CreateDeptMessage(
    $input: CreateDeptMessageInput!
    $condition: ModelDeptMessageConditionInput
  ) {
    createDeptMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toDeptID
      toDept {
        id
        organisationID
        name
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const updateDeptMessage = /* GraphQL */ `
  mutation UpdateDeptMessage(
    $input: UpdateDeptMessageInput!
    $condition: ModelDeptMessageConditionInput
  ) {
    updateDeptMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toDeptID
      toDept {
        id
        organisationID
        name
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const deleteDeptMessage = /* GraphQL */ `
  mutation DeleteDeptMessage(
    $input: DeleteDeptMessageInput!
    $condition: ModelDeptMessageConditionInput
  ) {
    deleteDeptMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toDeptID
      toDept {
        id
        organisationID
        name
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const createAllMessage = /* GraphQL */ `
  mutation CreateAllMessage(
    $input: CreateAllMessageInput!
    $condition: ModelAllMessageConditionInput
  ) {
    createAllMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const updateAllMessage = /* GraphQL */ `
  mutation UpdateAllMessage(
    $input: UpdateAllMessageInput!
    $condition: ModelAllMessageConditionInput
  ) {
    updateAllMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const deleteAllMessage = /* GraphQL */ `
  mutation DeleteAllMessage(
    $input: DeleteAllMessageInput!
    $condition: ModelAllMessageConditionInput
  ) {
    deleteAllMessage(input: $input, condition: $condition) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const createEnquiry = /* GraphQL */ `
  mutation CreateEnquiry(
    $input: CreateEnquiryInput!
    $condition: ModelEnquiryConditionInput
  ) {
    createEnquiry(input: $input, condition: $condition) {
      id
      email
      phone
      message
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEnquiry = /* GraphQL */ `
  mutation UpdateEnquiry(
    $input: UpdateEnquiryInput!
    $condition: ModelEnquiryConditionInput
  ) {
    updateEnquiry(input: $input, condition: $condition) {
      id
      email
      phone
      message
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEnquiry = /* GraphQL */ `
  mutation DeleteEnquiry(
    $input: DeleteEnquiryInput!
    $condition: ModelEnquiryConditionInput
  ) {
    deleteEnquiry(input: $input, condition: $condition) {
      id
      email
      phone
      message
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createNotice = /* GraphQL */ `
  mutation CreateNotice(
    $input: CreateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    createNotice(input: $input, condition: $condition) {
      id
      organisationID
      title
      description
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      comment {
        items {
          id
          comment
          memberID
          noticeId
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNotice = /* GraphQL */ `
  mutation UpdateNotice(
    $input: UpdateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    updateNotice(input: $input, condition: $condition) {
      id
      organisationID
      title
      description
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      comment {
        items {
          id
          comment
          memberID
          noticeId
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNotice = /* GraphQL */ `
  mutation DeleteNotice(
    $input: DeleteNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    deleteNotice(input: $input, condition: $condition) {
      id
      organisationID
      title
      description
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      comment {
        items {
          id
          comment
          memberID
          noticeId
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            maxHours
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              shiftCompliance
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              earlyClockInNotificationHours
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedFinancials
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createNoticeComments = /* GraphQL */ `
  mutation CreateNoticeComments(
    $input: CreateNoticeCommentsInput!
    $condition: ModelNoticeCommentsConditionInput
  ) {
    createNoticeComments(input: $input, condition: $condition) {
      id
      comment
      memberID
      noticeId
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const updateNoticeComments = /* GraphQL */ `
  mutation UpdateNoticeComments(
    $input: UpdateNoticeCommentsInput!
    $condition: ModelNoticeCommentsConditionInput
  ) {
    updateNoticeComments(input: $input, condition: $condition) {
      id
      comment
      memberID
      noticeId
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const deleteNoticeComments = /* GraphQL */ `
  mutation DeleteNoticeComments(
    $input: DeleteNoticeCommentsInput!
    $condition: ModelNoticeCommentsConditionInput
  ) {
    deleteNoticeComments(input: $input, condition: $condition) {
      id
      comment
      memberID
      noticeId
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const createStock = /* GraphQL */ `
  mutation CreateStock(
    $input: CreateStockInput!
    $condition: ModelStockConditionInput
  ) {
    createStock(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      stockLavel
      item
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateStock = /* GraphQL */ `
  mutation UpdateStock(
    $input: UpdateStockInput!
    $condition: ModelStockConditionInput
  ) {
    updateStock(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      stockLavel
      item
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteStock = /* GraphQL */ `
  mutation DeleteStock(
    $input: DeleteStockInput!
    $condition: ModelStockConditionInput
  ) {
    deleteStock(input: $input, condition: $condition) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      stockLavel
      item
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createShiftTemplate = /* GraphQL */ `
  mutation CreateShiftTemplate(
    $input: CreateShiftTemplateInput!
    $condition: ModelShiftTemplateConditionInput
  ) {
    createShiftTemplate(input: $input, condition: $condition) {
      id
      organisationID
      shiftStart
      shiftEnd
      roleID
      role {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      hasBreak
      breakStart
      breakEnd
      breakDuration
      unPaidBreak
      setAsClose
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateShiftTemplate = /* GraphQL */ `
  mutation UpdateShiftTemplate(
    $input: UpdateShiftTemplateInput!
    $condition: ModelShiftTemplateConditionInput
  ) {
    updateShiftTemplate(input: $input, condition: $condition) {
      id
      organisationID
      shiftStart
      shiftEnd
      roleID
      role {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      hasBreak
      breakStart
      breakEnd
      breakDuration
      unPaidBreak
      setAsClose
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteShiftTemplate = /* GraphQL */ `
  mutation DeleteShiftTemplate(
    $input: DeleteShiftTemplateInput!
    $condition: ModelShiftTemplateConditionInput
  ) {
    deleteShiftTemplate(input: $input, condition: $condition) {
      id
      organisationID
      shiftStart
      shiftEnd
      roleID
      role {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      hasBreak
      breakStart
      breakEnd
      breakDuration
      unPaidBreak
      setAsClose
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      organisationID
      folderId
      name
      type
      size
      tag
      fileURI
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      organisationID
      folderId
      name
      type
      size
      tag
      fileURI
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      organisationID
      folderId
      name
      type
      size
      tag
      fileURI
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFolder = /* GraphQL */ `
  mutation CreateFolder(
    $input: CreateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    createFolder(input: $input, condition: $condition) {
      id
      organisationID
      name
      files {
        items {
          id
          organisationID
          folderId
          name
          type
          size
          tag
          fileURI
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFolder = /* GraphQL */ `
  mutation UpdateFolder(
    $input: UpdateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    updateFolder(input: $input, condition: $condition) {
      id
      organisationID
      name
      files {
        items {
          id
          organisationID
          folderId
          name
          type
          size
          tag
          fileURI
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder(
    $input: DeleteFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    deleteFolder(input: $input, condition: $condition) {
      id
      organisationID
      name
      files {
        items {
          id
          organisationID
          folderId
          name
          type
          size
          tag
          fileURI
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createExtraPayDay = /* GraphQL */ `
  mutation CreateExtraPayDay(
    $input: CreateExtraPayDayInput!
    $condition: ModelExtraPayDayConditionInput
  ) {
    createExtraPayDay(input: $input, condition: $condition) {
      id
      organisationID
      baseDay
      newPay
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateExtraPayDay = /* GraphQL */ `
  mutation UpdateExtraPayDay(
    $input: UpdateExtraPayDayInput!
    $condition: ModelExtraPayDayConditionInput
  ) {
    updateExtraPayDay(input: $input, condition: $condition) {
      id
      organisationID
      baseDay
      newPay
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteExtraPayDay = /* GraphQL */ `
  mutation DeleteExtraPayDay(
    $input: DeleteExtraPayDayInput!
    $condition: ModelExtraPayDayConditionInput
  ) {
    deleteExtraPayDay(input: $input, condition: $condition) {
      id
      organisationID
      baseDay
      newPay
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPunch = /* GraphQL */ `
  mutation CreatePunch(
    $input: CreatePunchInput!
    $condition: ModelPunchConditionInput
  ) {
    createPunch(input: $input, condition: $condition) {
      id
      organisationID
      shiftID
      in
      breaks
      out
      baseDay
      imageReferences
      status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePunch = /* GraphQL */ `
  mutation UpdatePunch(
    $input: UpdatePunchInput!
    $condition: ModelPunchConditionInput
  ) {
    updatePunch(input: $input, condition: $condition) {
      id
      organisationID
      shiftID
      in
      breaks
      out
      baseDay
      imageReferences
      status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePunch = /* GraphQL */ `
  mutation DeletePunch(
    $input: DeletePunchInput!
    $condition: ModelPunchConditionInput
  ) {
    deletePunch(input: $input, condition: $condition) {
      id
      organisationID
      shiftID
      in
      breaks
      out
      baseDay
      imageReferences
      status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCheckIn = /* GraphQL */ `
  mutation CreateCheckIn(
    $input: CreateCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    createCheckIn(input: $input, condition: $condition) {
      id
      organisationID
      name
      duration
      unit
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCheckIn = /* GraphQL */ `
  mutation UpdateCheckIn(
    $input: UpdateCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    updateCheckIn(input: $input, condition: $condition) {
      id
      organisationID
      name
      duration
      unit
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCheckIn = /* GraphQL */ `
  mutation DeleteCheckIn(
    $input: DeleteCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    deleteCheckIn(input: $input, condition: $condition) {
      id
      organisationID
      name
      duration
      unit
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCompletedCheckIn = /* GraphQL */ `
  mutation CreateCompletedCheckIn(
    $input: CreateCompletedCheckInInput!
    $condition: ModelCompletedCheckInConditionInput
  ) {
    createCompletedCheckIn(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      checkInID
      organisationID
      note
      date
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCompletedCheckIn = /* GraphQL */ `
  mutation UpdateCompletedCheckIn(
    $input: UpdateCompletedCheckInInput!
    $condition: ModelCompletedCheckInConditionInput
  ) {
    updateCompletedCheckIn(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      checkInID
      organisationID
      note
      date
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCompletedCheckIn = /* GraphQL */ `
  mutation DeleteCompletedCheckIn(
    $input: DeleteCompletedCheckInInput!
    $condition: ModelCompletedCheckInConditionInput
  ) {
    deleteCompletedCheckIn(input: $input, condition: $condition) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        maxHours
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          shiftCompliance
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          earlyClockInNotificationHours
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedFinancials
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              maxHours
              employeeNu
              empRateUnit
              assignedAdmin
              assignedFinancials
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      checkInID
      organisationID
      note
      date
      createdAt
      updatedAt
      owner
    }
  }
`;

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState } from "react";
import { Button, Form, InputField, Yup, showFailure, useSimpleMessage } from "ww-framework";
import { OrganisationContext, orgUtils, personUtils } from "ww-stores";
const noteSchema = Yup.object().shape({
	note: Yup.string().required().min(2)
});
const calculateTotalTime = (shiftArray, organisation) => {
	return shiftArray.reduce((previousTotal, currentShift) => {
		let breakDuration = 0;
		if (currentShift?.hasBreak && (currentShift?.unPaidBreak || organisation?.allPaidBreak === true)) {
			if (currentShift.breakDuration) {
				breakDuration = currentShift.breakDuration * 60; // Convert minutes to seconds
			} else if (currentShift.breakStart && currentShift.breakEnd) {
				breakDuration = currentShift.breakEnd - currentShift.breakStart;
			}
		}
		const hoursWorked = (currentShift.shiftEnd - currentShift.shiftStart - breakDuration) / 3600;
		return previousTotal + hoursWorked;
	}, 0);
};
const calculatePunchTime = (punch, unPaidBreak, breakDuration, breakStart, breakEnd, hasBreak, organisation) => {
	let calculatedBreakDuration = 0;
	let punchedBreakDuration = 0;

	// Check if punch breaks are present and calculate their duration
	if (punch && Object.keys(punch).length && punch?.breaks?.length > 0) {
		punchedBreakDuration = punch?.breaks.reduce((previousTotal, currentBreak) => {
			let b = JSON.parse(currentBreak);
			let breakTime = (b?.end ?? 0) - b?.start;

			// Treat clock-in and clock-out at the same minute as a valid zero-duration break
			if (b?.start === b?.end) breakTime = 0;

			return previousTotal + breakTime;
		}, 0);

		// If punch breaks exist, use them and ignore other breaks
		if (punchedBreakDuration > 0 || punch.breaks.length > 0) {
			calculatedBreakDuration = punchedBreakDuration;
		}
	} else {
		// Only deduct breaks if there are no punch breaks and the conditions are met
		if (hasBreak && (unPaidBreak || organisation.allPaidBreak === true)) {
			calculatedBreakDuration = breakDuration * 60;

			// Add timestamp-based breaks if provided
			if (breakStart && breakEnd) {
				const timestampBreakDuration = breakEnd - breakStart;
				calculatedBreakDuration += timestampBreakDuration;
			}
		}
	}

	// If organisation has allPaidBreak set to true and the break is unpaid, no breaks should be deducted
	if (organisation.allPaidBreak === true && unPaidBreak === false) {
		calculatedBreakDuration = 0;
	}

	// Calculate total work time excluding breaks
	let calculatedHours = 0;
	if (punch?.out > punch?.in) {
		calculatedHours = (punch?.out - punch?.in - calculatedBreakDuration) / 3600;
	} else if (punch?.out < punch?.in) {
		// Handle shifts that cross over midnight
		calculatedHours = (punch?.out + 86400 - punch?.in - calculatedBreakDuration) / 3600;
	}

	// Return both the calculated hours and the deducted break duration
	return { calculatedHours, calculatedBreakDuration };
};

const NoteToHrForm = ({ handlerModal, activeDay, calendarDays, organisation, setToMailData, timeOff }) => {
	const { setMessage } = useSimpleMessage();
	const [noticeSaving, setNoticeSaving] = useState(false);
	const disableClockIn = organisation?.disableClockIn === "YES";
	const utf8_to_b64 = (str) => window.btoa(unescape(encodeURIComponent(str)));

	return (
		<>
			<Form
				validationSchema={noteSchema}
				enableReinitialize={true}
				disabled={noticeSaving}
				initialValues={{ note: "" }}
				onSubmit={async (data, { resetForm }) => {
					try {
						setNoticeSaving(true);

						if (organisation?.hrEmail) {
							let startEndBreakTime = false;
							let iniBreakValue = 0;
							let breakDate = "";
							const { members } = organisation;
							const lastDay = calendarDays[6]?.baseEpoch;
							const wholeYearShifts = await orgUtils.getCurrentYearShifts(organisation?.id, lastDay);
							const paidTimeOff = await orgUtils.getPaidTimeOff(organisation?.id, lastDay);
							const punches = await orgUtils.getPunchBetweenDay(organisation?.id, calendarDays[0].baseEpoch, calendarDays[6].baseEpoch);
							let thisWeekShifts = [];
							// get all shifts from calendar days and set them to thisWeekShifts
							for (let i = 0; i < calendarDays.length; i++) {
								thisWeekShifts = thisWeekShifts.concat(calendarDays[i].shifts);
							}

							// some shifts a deleted and their punches not deleted.
							// we remove these punches and keep only the valid ones
							const validWeekPunches = punches?.items?.filter((p) => thisWeekShifts?.find((s) => s?.id === p?.shiftID));

							if (validWeekPunches?.length > 0) {
								validWeekPunches?.map((pn) => {
									breakDate = new Date(pn?.baseDay * 1000);
									breakDate = new Intl.DateTimeFormat().format(breakDate);
									if (pn?.breaks?.length > 0) {
										pn?.breaks?.map((brc) => {
											let breakTimes = JSON.parse(brc);
											if (breakTimes?.start && breakTimes.end) startEndBreakTime = false;
											else {
												startEndBreakTime = true;
												iniBreakValue++;
											}
										});
									}
								});
							}

							const extraPay = await orgUtils.getExtraPayDay(organisation.id, calendarDays[0].baseEpoch, lastDay);
							const csvData = [];
							csvData[0] = [];
							let negetiveData = false;
							const adminEmail = members?.find((m) => m?.person === organisation?.owner)?.email ?? "";
							await Promise.all(
								members?.map(async (m, i) => {
									if (i === 0) csvData[0]?.push("Name", "Employee No");
									let name = m?.firstName || m?.lastName ? `${m?.firstName} ${m?.lastName}` : personUtils.displayName(m);
									csvData[i + 1] = [];
									csvData[i + 1].push(name, m?.employeeNu ?? "");
									let totalHours = 0;
									let standardHours = 0;
									let hours150 = 0;
									let hours200 = 0;
									let customPayHours = {};

									let memberShifts = [];
									// we are getting member shifts from wholeYearShifts, if the wholeYearShifts shifts are less than 1000.
									// if the shifts are 1000 or more, that means the shifts have been paginated and hence we can't use those since some shifts are missing.
									// We then get each member's shifts using getMemberYearShifts.
									// wholeYearShifts is used to save processing time and avoid making so many queries, if the organisation has less than 1000 shifts.
									// we can consider getting rid of wholeYearShifts and just use getMemberYearShifts, but that would mean more queries and more processing time.
									if (wholeYearShifts?.items?.length < 1000) {
										memberShifts = wholeYearShifts?.items?.filter(
											(shifts) => shifts?.memberID === m?.orgUserId && shifts?.baseDay < lastDay + 23 * 60 * 60 + 59 * 60
										);
									} else {
										const memberYearShifts = await orgUtils.getMemberYearShifts(organisation?.id, m?.orgUserId, calendarDays[6]);
										memberShifts = memberYearShifts?.items?.map((item) => item);
									}

									let hoursWorkedThisYearTillThisWeek = calculateTotalTime(memberShifts);
									let memberPaidHoursTaken =
										paidTimeOff?.items
											.filter((off) => off?.memberID === m.orgUserId)
											.reduce((previousTotal, currentOff) => {
												return previousTotal + (currentOff?.toDate - currentOff?.fromDate) / (60 * 60 * 24);
											}, 0) * 8;
									let memberPaidHoursThisWeek =
										timeOff
											?.filter((off) => off.memberID === m.orgUserId && off.status === "APPROVE" && off.isPaid === true)
											.reduce((previousTotal, currentOff) => {
												return (
													previousTotal +
													((currentOff?.toDate < calendarDays[6].baseEpoch
														? currentOff?.toDate
														: calendarDays[6].baseEpoch) -
														(currentOff?.fromDate > calendarDays[0].baseEpoch
															? currentOff?.fromDate
															: calendarDays[0].baseEpoch)) /
														(60 * 60 * 24)
												);
											}, 0) * 8;
									await Promise.all(
										calendarDays.map((c) => {
											const extraDay = extraPay?.items.find((k) => k.baseDay === c.baseEpoch);
											if (i === 0) {
												csvData[0].push(`${c.formatted} ${extraDay?.newPay ? " + (" + extraDay.newPay + "%)" : ""}`);
											}

											let shiftTime = 0; // Initialize shiftTime for the day
											let shiftsForMember = c.shifts.filter((s) => s.memberID === m.orgUserId); // Get all shifts for this member on this day

											shiftsForMember.forEach((shift) => {
												let unPaidBreak = shift?.unPaidBreak || organisation?.allPaidBreak;
												let punch = validWeekPunches?.find((p) => p?.shiftID === shift?.id); // Match punch to shift

												if (!disableClockIn && validWeekPunches?.length) {
													// Calculate time using punch data
													const { calculatedHours, calculatedBreakDuration } = calculatePunchTime(
														punch,
														unPaidBreak,
														shift?.breakDuration,
														shift?.breakStart,
														shift?.breakEnd,
														shift?.hasBreak,
														organisation
													);
													shiftTime += parseFloat(calculatedHours?.toFixed(1).replace(/[.,]00$/, "") ?? 0); // Accumulate shift time for the day
												} else {
													// Fallback to calculate shift time without punches
													shiftTime += calculateTotalTime([shift]); // Add the shift time for this single shift
												}
											});

											if (shiftTime < 0) {
												negetiveData = negetiveData !== false ? negetiveData : "" + c.formatted + " ";
											}

											// Handle extra pay and accumulation logic
											if (extraDay?.newPay && shiftsForMember?.some((s) => s.newPay)) {
												const newPay = extraDay.newPay + shiftsForMember[0].newPay;
												if (newPay === 50) {
													hours150 += shiftTime;
												} else if (newPay === 100) {
													hours200 += shiftTime;
												} else {
													if (!customPayHours[newPay]) {
														customPayHours[newPay] = 0;
													}
													customPayHours[newPay] += shiftTime;
												}
											} else if (extraDay?.newPay) {
												if (extraDay.newPay === 50) {
													hours150 += shiftTime;
												} else if (extraDay.newPay === 100) {
													hours200 += shiftTime;
												} else {
													if (!customPayHours[extraDay.newPay]) {
														customPayHours[extraDay.newPay] = 0;
													}
													customPayHours[extraDay.newPay] += shiftTime;
												}
											} else if (shiftsForMember?.some((s) => s.newPay)) {
												const newPay = shiftsForMember[0].newPay;
												if (newPay === 50) {
													hours150 += shiftTime;
												} else if (newPay === 100) {
													hours200 += shiftTime;
												} else {
													if (!customPayHours[newPay]) {
														customPayHours[newPay] = 0;
													}
													customPayHours[newPay] += shiftTime;
												}
											} else {
												standardHours += shiftTime;
											}

											totalHours += shiftTime;
											csvData[i + 1]?.push(
												shiftsForMember?.some((s) => s.newPay)
													? shiftTime + "  ( +" + shiftsForMember[0].newPay + "%)"
													: shiftTime
											);
											return null;
										})
									)

										.then(() => {
											if (i === 0) csvData[0]?.push("Total Hours", "Standard Pay", "+50% Pay", "+100% Pay", "Custom Pay");
											let customPayEntries = Object.keys(customPayHours).map((percentage) =>
												customPayHours[percentage] === 0 ? "0" : `${customPayHours[percentage]} (+${percentage}%)`
											);

											// Filter out '0' entries if they are not the last/only entry
											if (customPayEntries.length > 1) {
												customPayEntries = customPayEntries.filter(
													(entry, index) => entry !== "0" || index === customPayEntries.length - 1
												);
											}

											let customPaySummary = customPayEntries.join(", ");

											csvData[i + 1]?.push(totalHours, standardHours, hours150, hours200, customPaySummary);
										})
										.then(() => {
											if (i === 0) csvData[0]?.push("Hours This Week");
											csvData[i + 1]?.push(totalHours);
										})
										.then(() => {
											if (i === 0) csvData[0]?.push("Previous Hours This Year");
											csvData[i + 1]?.push(hoursWorkedThisYearTillThisWeek.toFixed(2));
										})
										.then(() => {
											if (i === 0) csvData[0].push("Total Holiday Hours This Year");
											csvData[i + 1]?.push((hoursWorkedThisYearTillThisWeek * 0.08).toFixed(2));
										})
										.then(() => {
											if (i === 0) csvData[0]?.push("Paid Holidays This Week");
											csvData[i + 1]?.push(memberPaidHoursThisWeek > 0 ? (memberPaidHoursThisWeek + 8).toFixed(2) : 0);
										})
										.then(() => {
											if (i === 0) csvData[0]?.push("Overall Paid Holidays");
											csvData[i + 1]?.push(memberPaidHoursTaken > 0 ? (memberPaidHoursTaken + 8).toFixed(2) : 0);
										})
										.then(() => {
											if (i === 0) csvData[0]?.push("Overall Holiday Hours");
											csvData[i + 1]?.push(
												(
													hoursWorkedThisYearTillThisWeek * 0.08 -
													(memberPaidHoursTaken > 0 ? memberPaidHoursTaken + 8 : 0)
												)?.toFixed(2)
											);
										});
								})
							);

							const toMail = {};
							let stringToReplaceComas = "!!!!";
							csvData?.map((singleRow) => {
								singleRow?.map((value, index) => {
									singleRow[index] = value?.toString().replace(/,/g, stringToReplaceComas);
									return null;
								});
								return null;
							});
							let csv = `"${csvData?.join('"\n"').replace(/,/g, '","')}"`;
							csv = csv.replace(new RegExp(`${stringToReplaceComas}`, "g"), ",");
							toMail.attachments = [
								{
									filename: `Report-${calendarDays[0]?.formatted + "-" + calendarDays[6]?.formatted}.csv`,
									content: utf8_to_b64(csv),
									encoding: "base64"
								}
							];
							toMail.subject = "Hourly Report of " + calendarDays[0].formatted + " to " + calendarDays[6]?.formatted;
							toMail.to = [organisation?.hrEmail, adminEmail];
							toMail.mailBody = "Here is the Report of the week, Also admin's note :- " + data?.note;

							setNoticeSaving(false);
							resetForm();
							if (startEndBreakTime && iniBreakValue > 0) {
								setToMailData({});
								handlerModal("", "close");
								setMessage(
									showFailure({
										title: `Break not clocked out by member on ${breakDate}`
									})
								);
								return false;
							}
							if (negetiveData) {
								setToMailData({});
								handlerModal("", "close");
								setMessage(
									showFailure({
										title: `Member not clocked out on ${negetiveData}`
									})
								);
							} else {
								handlerModal("", "saved");
								setToMailData(toMail);
							}
						} else {
							setMessage(
								showFailure({
									title: "Please add HR Email in General Setting first!"
								})
							);
							return;
						}
					} catch (error) {
						setNoticeSaving(false);
						setMessage(
							showFailure({
								title: "Unable to generate report, please contact Support.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				<div className="w-full">
					<div className="my-5">
						<InputField label="Add note for payroll." name="note" />
					</div>
					<div className="w-full mt-1">
						<Button type="submit" label="Save" disabled={noticeSaving} />
					</div>
				</div>
			</Form>
		</>
	);
};

const EmailWorkHour = ({ calendarDays, activeDay, open, handlerModal, setToMailData, timeOff }) => {
	const deduplicateCalendarDays = (days) => {
		const seen = new Set();
		return days.filter((day) => {
			const identifier = `${day.day}-${day.month}-${day.year}`;
			if (seen.has(identifier)) {
				return false;
			}
			seen.add(identifier);
			return true;
		});
	};

	// Apply the deduplication filter to calendarDays
	const uniqueCalendarDays = deduplicateCalendarDays(calendarDays);
	const { organisation } = useContext(OrganisationContext);
	// const { person } = useContext(PersonContext);
	// console.log(timeOff);
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
				<div className="flex items-center justify-center h-full w-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform rounded-lg w-fit w-96">
							<span className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer" onClick={(e) => handlerModal(e, "close")}>
								<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							<div className="w-full py-3 px-2">
								<div className="justify-center gap-2">
									<NoteToHrForm
										organisation={organisation}
										handlerModal={handlerModal}
										calendarDays={uniqueCalendarDays}
										activeDay={activeDay}
										setToMailData={setToMailData}
										timeOff={timeOff}
									/>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default EmailWorkHour;
